// @ts-nocheck
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import Fancybox from "app/_components/Fancybox";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Column } from "react-table";

import { Tag } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import { UsersList } from "app/_modules/user-management/pages/users-list";
import { randomAntdColor } from "app/utils";
import * as React from "react";

const usersColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ID"
        className="min-w-50px text-start"
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "id",
    accessor: "id",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="IMAGE"
        className="min-w-100px text-center"
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "image",
    Cell: ({ ...props }) => {
      const row = props.row.original;
      return <RenderEventImg row={row} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="TITLE"
        className="min-w-50px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "title",
    accessor: "title",
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      const lang = JSON.parse(localStorage.getItem("i18nConfig"));
      try {
        const arrayTitle = JSON.parse(item?.title) || [];
        if (lang && typeof arrayTitle === "object") {
          const existData = arrayTitle.find(
            (item: any) => item?.lang === lang?.selectedLang
          );
          if (existData) {
            return <p className="mb-0">{existData.text}</p>;
          } else {
            return null;
          }
        }
      } catch (error) {
        if (typeof item?.title == "string") {
          return <p className="mb-0">{item?.title}</p>;
        }
        return null;
      }
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="DEVICE"
        className="min-w-100x"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "device_type",
    accessor: "device_type",
    isCenterRow: true,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="START-AT"
        className="min-w-100px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
        isForceDesc={true}
      />
    ),
    id: "start_date",
    accessor: "start_date",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.start_date;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="END-AT"
        className="min-w-100x"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "end_date",
    accessor: "end_date",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.end_date;
      return <DateTimeCell date={date} />;
    },
  },
];

function DeleteComponent({ row }) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {/* <Popconfirm
        title={intl.formatMessage({ id: "DELETE" })}
        description={intl.formatMessage({ id: "ARTICLE.DELETE.CONFIRM" })}
        onConfirm={async () => {
          const res = await delete_admin(row.id);
          if (res.status == 200) refetch();
        }}
        onCancel={() => {}}
        okText={intl.formatMessage({ id: "YES" })}
        cancelText={intl.formatMessage({ id: "NO" })}
      >
        <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
          <KTIcon iconName="trash" className="fs-2" />
        </button>
      </Popconfirm> */}
    </div>
  );
}

function ModalUserLikePost({ row, type }) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [pag, setPag] = React.useState({});
  const [params, setParams] = React.useState({
    page: 1,
    article_id: row.id,
  });
  const handleOpen = () => {
    if (row[type] > 0) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getListData = async () => {
    if (type === "like_count") {
      const res = await getUsersLikePost(params);
      setData(res.data);
      setPag(res.pagination);
    }
    if (type === "comment_count") {
      const res = await getUsersCommentPost(params);
      setData(res.data);
      setPag(res.pagination);
    }
  };

  React.useEffect(() => {
    if (open) {
      getListData();
    }
  }, [open]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        className={` ${row[type] > 0 ? "cursor-pointer text-primary" : ""}`}
        onClick={handleOpen}
      >
        {row[type]}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "80%",
            backgroundColor: "#fff",
            m: "auto",
            p: 4,
            border: "0 !important",
            outline: "none",
          }}
        >
          <div className="modal-header">
            <h2 id="parent-modal-title">
              {intl.formatMessage({
                id:
                  type === "like_count"
                    ? "LIST-USER-LIKE-POST"
                    : "LIST-USER-COMMENT-POST",
              })}
            </h2>
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              onClick={handleClose}
            >
              <KTIcon className="fs-1" iconName="cross" />
            </div>
            {/* end::Close */}
          </div>

          <Box sx={{ overflow: "auto", height: "90%" }}>
            <UsersList
              pagination={pag}
              // mode={type}
              data={data}
              type={type}
              columns={commentsColumns}
              // refetch={refetch}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

const RenderEventImg = ({ row }) => {
  const images = row.image;

  return (
    <div className="d-flex justify-content-center align-items-center">
      {/* begin:: Avatar */}
      <Fancybox
        options={{
          Carousel: {
            infinite: true,
          },
        }}
        key={Math.random()}
      >
        <div style={{ display: "inline-block", maxWidth: 70 }}>
          <div className="post_img">
            {images ? (
              <div className="symbol-label overflow-hidden">
                <a data-fancybox="gallery" href={images} key={Math.random()}>
                  <img src={images} style={{ maxHeight: 70, maxWidth: 70 }} />
                </a>
              </div>
            ) : (
              <div style={{ display: "none" }}>
                <a data-fancybox="gallery" href={url} key={Math.random()}>
                  <img src={url} width="70" height="70" />
                </a>
              </div>
            )}
          </div>
        </div>
      </Fancybox>

      {/* <div className="d-flex flex-column ms-3">
        <div className="text-gray-800 text-hover-primary mb-1">
          {row?.description}
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          {row?.hashtag?.map((i: any) => (
            <Tag
              style={{ marginBottom: 10, fontSize: 10 }}
              bordered={false}
              color={randomAntdColor()}
            >
              {i}
            </Tag>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export { usersColumns };
