import AllRank from "./all-rank";

import { PageTitle } from "_metronic/layout/core";
import { useIntl } from "react-intl";

const RankingPage = () => {
  const intl = useIntl();

  return (
    <>
      <PageTitle>
        {intl.formatMessage({
          id: "RANKING",
        })}
      </PageTitle>

      <AllRank />
    </>
  );
};

export default RankingPage;
