import { Avatar } from "antd";

export default function UserCell(user: any) {
    return (
      <div className="d-flex gap-3 align-items-center">
        <Avatar size="large" src={user?.avatar} alt="avatar">{!user?.avatar && 'U'}</Avatar>
        <div className="">
            <div className="text-gray-800 dw-bold d-block fs-7">
            {user?.name}
            </div>
            <div className="text-muted fw-semibold text-muted d-block fs-7">
            {user?.email}
            </div>
        </div>
      </div>
    );
  }
  