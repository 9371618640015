import { callApiWithTryCatch } from "app/utils/apiUntils";
import { applyDefaultQueryConditions, linkPagination } from "app/utils/index";
import axios from "axios";
import { UpdateLevel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_URL = `${API_URL}/users`;

export const getPanigationUsers = async (query: any,queryCustom:any) => {
  const newquery = applyDefaultQueryConditions(query);

  if (newquery?.platform === "apple") {
    newquery.platform = "apple,firebase_apple";
  }

  const res = await callApiWithTryCatch(
    async () => await axios.get(`${GET_USERS_URL}`, { params: newquery,headers:{
      'x-lang': queryCustom
    }})
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, pagination, user_platform,levelSettings } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    user_platform: user_platform,
    level_settings:levelSettings
  };
};

const edit_user = (id: any, body: any) => {
  return axios.put(`${GET_USERS_URL}/${id}/update`, body);
};

const delete_admin = (id: any) => {
  return axios.delete(`${GET_USERS_URL}/${id}/delete`);
};

const update_level = (params:UpdateLevel) => {
  return axios.patch(`${GET_USERS_URL}/${params.id}/level/${params.level}`);
};

export { delete_admin, edit_user, update_level };
