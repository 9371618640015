import axios, { AxiosResponse } from "axios";
import { SummaryResponse } from "./_models";
import { callApiWithTryCatch } from "app/utils/apiUntils";
import { linkPagination } from "app/utils";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_SUMMARY_URL = `${API_URL}/users/summary`;
const GET_USERS_URL = `${API_URL}/ranking`;
const GET_USERS_INVITATION_URL = `${API_URL}/ranking/invitation`;

const getUsersSummary = (): Promise<SummaryResponse> => {
  return (
    axios
      .get(`${GET_USERS_SUMMARY_URL}`)
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        return d.data.data;
      })
  );
};

export const getPaginationAllRankings = async (query: any) => {
  const newquery = { ...query };

  const res = await callApiWithTryCatch(
    async () => await axios.get(`${GET_USERS_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, pagination, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    user_platform: user_platform,
  };
};

export const getPaginationAllRankingsInvitation = async (query: any) => {
  const newquery = { ...query };

  const res = await callApiWithTryCatch(
    async () =>
      await axios.get(`${GET_USERS_INVITATION_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, pagination, user_platform } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, pagination.total, newquery?.limit),
      total: pagination.total,
    },
    user_platform: user_platform,
  };
};

export { getUsersSummary };
