import { PageTitle } from "_metronic/layout/core";
import { FilterHeader } from "_metronic/layout/core/FilterHeaderData";
import clsx from "clsx";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useIntl } from "react-intl";
import ActiveUserChartWrapper from "./components/ActiveUserChart";
import AppDownloadChart from "./components/AppDownloadChart";
import FilterByDate from "./components/FilterByDate";
import PostUploadChartWrapper from "./components/PostUploadChart";
import { RealtimeActiveUsersChartWrapper } from "./components/RealtimeActiveUsersChart";
import UserRegistrationChartWrapper from "./components/UserRegistrationChart";
import { VideoUploadChartWrapper } from "./components/VideoUploadChart";
import VideoViewsReportChartWrapper from "./components/VideoViewsReportChart";
import { useQueryRequest } from "./components/core/QueryRequestProvider";
import { LikeAndCommentChartWrapper } from "./components/LikeAndCommentChart";

dayjs.locale("pl");

export default function OverviewPage() {
  const intl = useIntl();
  const { updateState, state } = useQueryRequest();

  // const chartRefViewsVideo = useRef<HTMLDivElement | null>(null);

  // const dataViewsChart = useQueryVideoViewsReportResponseData();

  // const { isLoadingViewsChart } = useQueryResponse();

  // const yViewsChart = dataViewsChart.map((item) => item.view_total);

  // const xViewsChart = dataViewsChart.map((item) => item.date);

  // const lastItemViewsChart = dataViewsChart[dataViewsChart.length - 1];

  return (
    <div className="row g-5 g-xl-10 mb-5 position-relative mb-xl-10 justify-content-start">
      <PageTitle>
        <div
          id="kt_page_title"
          data-kt-swapper="true"
          data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 5,
              position: "relative",
            }}
          >
            <div className={clsx("menu-title fw-bold fs-5")}>
              {intl.formatMessage({ id: "MENU.DASHBOARD" })}
            </div>
          </div>
        </div>
      </PageTitle>

      <FilterHeader>
        <div className="py-3">
          <FilterByDate
            updateState={updateState}
            state={state}
            typeChart="content"
          />
        </div>
      </FilterHeader>

      <div /* style={{ display: "flex" }} */>
        {/*  <div style={{ position: "absolute", flex: 1 }}>
        <Globe />
      </div> */}
        {/* begin::Row */}
        <div className="row g-5 g-xl-10">
          <div className="col-xxl-12 p-4">
            <div className="row">
              <div className="col-md-6 col-xxl-4 mb-5">
                <UserRegistrationChartWrapper className={"h-375px"} />
              </div>

              <div className="col-md-6 col-xxl-4 mb-5">
                <AppDownloadChart className={"h-375px"} />
              </div>
              <div className="col-md-6 col-xxl-4 mb-5">
                <ActiveUserChartWrapper isPageMode className={"h-375px"} />
              </div>
              <div className="col-md-6 col-xxl-4 mb-5">
                <RealtimeActiveUsersChartWrapper className="h-375px" />
              </div>

              <div className="col-md-6 col-xxl-4 mb-5">
                <PostUploadChartWrapper className={"h-375px"} />
              </div>
              <div className="col-md-6 col-xxl-4 mb-5">
                <LikeAndCommentChartWrapper className={"h-375px"} />
              </div>

              <div className="col-md-6 col-xxl-4 mb-5">
                <VideoUploadChartWrapper className={"h-375px"} />
              </div>
              <div className="col-md-6 col-xxl-4 mb-5">
                <VideoViewsReportChartWrapper className="h-375px" />
              </div>
            </div>
          </div>
        </div>
        {/* end::Row */}
      </div>
    </div>
  );
}
