// @ts-nocheck
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import { Column } from "react-table";
import { User } from "../core/_models";

import UserInfoCell from "app/_components/table/columns/UserInfoCell";

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="RANK"
        className="min-w-50px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "rank",
    accessor: "rank",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USER"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "user",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <UserInfoCell user={row} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="TOTAL-POINT"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
        isForceDesc={true}
      />
    ),
    id: "total_point",
    accessor: "total_point",
    isCenterRow: true,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="POST-POINT"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "post_point",
    accessor: "post_point",
    isCenterRow: true,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="LIKE-POINT"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "like_point",
    accessor: "like_point",
    isCenterRow: true,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="COMMENT-POINT"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "comment_point",
    accessor: "comment_point",
    isCenterRow: true,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="FOLLOW-POINT"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "follow_point",
    accessor: "follow_point",
    isCenterRow: true,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="INVITE-POINT"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "invite_point",
    accessor: "invite_point",
    isCenterRow: true,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="INVITED-POINT"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "invited_point",
    accessor: "invited_point",
    isCenterRow: true,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="REGISTER-POINT"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "register_point",
    accessor: "register_point",
    isCenterRow: true,
  },
];

export { usersColumns };
