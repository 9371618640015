import { FC, useContext, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  WithChildren,
} from "_metronic/helpers";
import { useQueryRequest } from "./QueryRequestProvider";
import { isEmptyObject } from "jquery";
import moment from "moment";

interface QueryResponseProviderProps extends WithChildren {
  module?: string;
  params: any;
  getPanigationData?: (query: any, custom: any) => Promise<any>;
}

const QueryResponseContext = createResponseContext<any>(initialQueryResponse);

const QueryResponseProvider: FC<QueryResponseProviderProps> = ({
  children,
  module,
  params,
  getPanigationData,
}) => {
  const { state } = useQueryRequest();

  const [query, setQuery] = useState<any>();
  const updatedQuery = useMemo(() => {
    let customize_users_query = {
      ...(state.page && { page: state.page }),
      ...(state.items_per_page && { limit: state.items_per_page }),
      ...(state.search && { keyword: state.search }),
      ...(state.status && state.status !== "all" && { status: state.status }),
      ...(state.start_date && {
        start_date: moment(state.start_date).format("YYYY-MM-DD"),
      }),
      ...(state.end_date && {
        end_date: moment(state.end_date).format("YYYY-MM-DD"),
      }),
      ...(state.platform &&
        state.platform.length > 0 && {
          platform: state.platform.toString(),
        }),
      ...(state.sort && { sort_by: state.sort }),
      ...(state.order && { order_by: state.order.toUpperCase() }),
      ...(state.is_deleted && {
        is_deleted: state.is_deleted === "DELETED" ? true : false,
      }),
      ...(state.article_type && { article_type: state.article_type }),
      ...(state.device_type && { device_type: state.device_type }),
      ...(state.push_status && { push_status: state.push_status }),
      ...(state.type && { type: state.type }),
      ...(state.group && { group: state.group }),
      ...(state.device_os &&
        state.device_os !== "all" && { device_os: state.device_os }),
      ...(state.role_id && { role_id: state.role_id }),
      ...(state.extra_type && { extra_type: state.extra_type }),

      ...(state.resource && { resource: state.resource }),
      ...(state.file_type && { file_type: state.file_type }),
      ...(state.category && { category: state.category }),
    };
    return customize_users_query;
  }, [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [state]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [query, module],
    () => {
      if (!query) return;
      return getPanigationData && getPanigationData(query, params);
    },
    { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response }: any = useQueryResponse();
  if (!response) {
    return [];
  }
  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response }: any = useQueryResponse();
  if (!response || !response?.pagination) {
    return defaultPaginationState;
  }
  return response.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
