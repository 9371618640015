import Tooltip from "@mui/material/Tooltip/Tooltip";
import { isEnableTooltip } from "_metronic/helpers";

export default function TooltipWrapper({
  children,
  text,
  limit,
  placement = "top-start",
}: any) {
  return (
    <Tooltip
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: "linear-gradient(195deg, #42424a, #191919)",
          },
        },
        arrow: {
          sx: {
            color: "linear-gradient(195deg, #42424a, #191919)",
          },
        },
      }}
      title={isEnableTooltip(text, limit) ? <p className="fs-5">{text}</p> : ""}
      arrow
      placement={placement}
    >
      {children}
    </Tooltip>
  );
}
