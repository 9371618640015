// @ts-nocheck
import { Column } from "react-table";
import { User } from "../../core/_models";
import Fancybox from "app/_components/Fancybox";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useIntl } from "react-intl";
import { DatePicker, notification } from "antd";
import dayjs from "dayjs";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { updatePostByUser } from "../../core/_requests";
import { CircularProgress } from "@mui/material";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import { useState } from "react";
import { POST_TYPE } from "app/constants";

const _listPostsOfUserColumn: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="STATUS"
        className="min-w-100px text-start"
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "is_deleted",
    accessor: "is_deleted",
    isLeftRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const intl = useIntl();

      return row?.is_deleted ? (
        <span className="badge badge-pill badge-danger">
          {intl.formatMessage({ id: "DELETED" })}
        </span>
      ) : (
        <span className="badge badge-pill badge-success">
          {intl.formatMessage({ id: "SHOW" })}
        </span>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="POST"
        className="min-w-100px"
        isNoSort={true}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "images",
    isLeftRow: true,
    Cell: ({ ...props }) => {
      const row = props.row.original;

      return <RenderPostInfo row={row} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ARTICLE_TYPE"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "article_type",
    isCenterRow: true,

    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const intl = useIntl();

      return intl.formatMessage({
        id: `${
          POST_TYPE.find((type) => type.value === row?.article_type)?.label
        }`,
      });
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="DETAILS"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "description",
    accessor: "description",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="CREATED-AT"
        className="min-w-100px"
        isNoSort={false}
        isForceDesc={true}
        isCenter={true}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "created_at",
    accessor: "created_at",
    isCenterRow: true,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="UPDATED-AT"
        className="min-w-100x"
        isNoSort={false}
        isCenter={true}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "updated_at",
    accessor: "updated_at",
    isCenterRow: true,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        className="min-w-50px"
        isNoSort={false}
        isCenter={true}
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "action",
    accessor: "updated_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;

      return <ActionPost row={row} />;
    },
  },
];

const ActionPost = ({ row }: any) => {
  const { refetch } = useQueryResponse();
  const intl = useIntl();
  const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState(false);

  const onSubmit = async (date: string) => {
    setIsLoadingDeleteUser(true);
    try {
      const result = await updatePostByUser(row.id, {
        created_at: dayjs(date).format("YYYY-MM-DD HH:mm:ss"),
      });

      if (result.status === 200) {
        notification.success({
          message: intl.formatMessage({ id: "UPDATE.POST-SUCCESS" }),
        });
        refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDeleteUser(false);
    }
  };

  return (
    <div className="position-relative cursor-pointer">
      {isLoadingDeleteUser ? (
        <CircularProgress size={16} />
      ) : (
        <>
          <EditCalendarIcon />
          <DatePicker
            format={"YYYY-MM-DD HH:mm"}
            value={row.created_at ? dayjs(row.created_at) : null}
            showTime={{ format: "HH:mm" }}
            onOk={onSubmit}
            allowClear={false}
            className="opacity-0 position-absolute top-0 h-20px"
          />
        </>
      )}
    </div>
  );
};

const RenderPostInfo = ({ row }) => {
  const URL_IMAGE = "https://pub-b2dcb11b55ca489198237cb2230622f7.r2.dev";
  // switch (row.article_type) {
  //   case "wish":
  //     imagesURL = [{ url: row.board_thumbnail }];
  //     break;
  //   case "goal":
  //     //puzzle image -> images includes thumbnail || images is empty
  //     //no => show all images, thumbnail meaninglesss

  //     //thumb + no image => thumb
  //     //thumb + images => images includes thumb => images (list images)
  //     //thumb + images => images not includes thumb => PUZZLE => thumb

  //     if (!row.images.includes(row.board_thumbnail) || row.images.length === 0)
  //       imagesURL = [{ url: row.board_thumbnail }];
  //     else
  //       imagesURL = row.images.map((image) => ({
  //         url: URL_IMAGE + image?.url,
  //         urlVideo: URL_IMAGE + image?.urlVideo,
  //       }));

  //     if (row.is_completed)
  //       imagesURL = row.images.map((image) => ({
  //         url: URL_IMAGE + image?.url,
  //         urlVideo: URL_IMAGE + image?.urlVideo,
  //       }));

  //     break;
  // }

  const imagesURL = [...row.images].map((image) => {
    const new_value = {};
    if (image.url) {
      new_value.url = URL_IMAGE + image.url;
    }

    if (image.urlVideo) {
      new_value.urlVideo = URL_IMAGE + image.urlVideo;
    }
    return new_value;
  });

  return (
    <div className="d-flex align-items-center">
      {/* begin:: Avatar */}
      <Fancybox
        options={{
          Carousel: {
            infinite: true,
          },
        }}
        key={Math.random()}
      >
        <div style={{ display: "inline-block", maxWidth: 70 }}>
          <div className="post_img">
            {imagesURL?.map((image, index) => (
              <div
                key={index}
                className="symbol symbol-70px me-3 position-relative"
              >
                {index == 0 ? (
                  <>
                    <div className="symbol-label overflow-hidden">
                      <a
                        data-fancybox="gallery"
                        href={row.board_thumbnail || imagesURL[0].url}
                        key={Math.random()}
                      >
                        <img
                          alt="post"
                          src={row.board_thumbnail || imagesURL[0].url}
                          style={{ maxHeight: 70, maxWidth: 70 }}
                        />
                      </a>
                    </div>
                  </>
                ) : (
                  <div style={{ display: "none" }}>
                    <a
                      data-fancybox="gallery"
                      href={image?.urlVideo || image?.url}
                      key={Math.random()}
                    >
                      <img alt="post" src={image?.url} width="70" height="70" />
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Fancybox>

      <div className="d-flex flex-column ms-3">
        <div
          className="text-gray-800 mb-1 text-break"
          style={{ whiteSpace: "normal", maxWidth: 300, minWidth: 200 }}
        >
          {row?.description}
        </div>
      </div>
    </div>
  );
};

export { _listPostsOfUserColumn };
