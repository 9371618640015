import {
  KTCard,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  QUERIES,
  hasPermission,
} from "_metronic/helpers";
import { PageTitle } from "../../../_metronic/layout/core";

import { FilterHeader } from "_metronic/layout/core/FilterHeaderData";
import TreeSelectC from "app/_components/TreeSelect";
import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { Table } from "app/_components/table/Table";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useAuth } from "../auth";
import {
  getListTypeSettingNotification,
  getPaginationListNotice,
} from "./core/_requests";
import { UsersListToolbar } from "./core/header/UserListToolbar";
import { usersColumns } from "./core/table/_columns";
import TableSummary from "app/_components/coreListData/header/TableSummary";
// import { CreateAppModal } from "./core/add-edit-user";

const NoticePage = () => {
  const intl = useIntl();
  const { state, updateState } = useQueryRequest();
  const { refetch } = useQueryResponse();
  const users = useQueryResponseData();
  const res = useQueryResponsePagination();

  const {
    isFetching: isLoadingTypeNotify,
    refetch: reLoadTypeNotify,
    data: responseTypeNotify,
  } = useQuery(
    [`${QUERIES.CREATE_NOTIFICATION}`],
    () => {
      return getListTypeSettingNotification();
    },
    {
      cacheTime: 20000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const { allPermission, myPermission } = useAuth();
  const hasPermissionDelete = hasPermission(
    PERMISSIONS_MAP.REMOVE_EVENT,
    myPermission,
    allPermission
  );

  let columns = usersColumns.filter((i) => {
    if (!hasPermissionDelete && i.id == "action-delete") return false;
    return true;
  });

  const listTypeNotify = useMemo(
    () =>
      (responseTypeNotify &&
        responseTypeNotify.data.length > 0 &&
        responseTypeNotify.data.map((item: any) => ({
          ...item,
          label: item.name,
          value: item.id,
        }))) ||
      [],
    [responseTypeNotify]
  );

  const cardList = [
    {
      title: intl.formatMessage({
        id: "Event",
      }),
      value: res?.total,
    },
  ];

  return (
    <>
      <PageTitle>{intl.formatMessage({ id: "NOTICE" })}</PageTitle>

      <FilterHeader>
        <div className="w-100 px-6 py-3 rounded d-flex align-items-center flex-wrap me-5 filter-header gap-4">
          <div className="card-toolbar align-items-center d-flex min-w-sm-250px min-w-100">
            <div className="bullet bg-secondary h-35px w-1px me-5 d-none d-md-block"></div>
            <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 min-w-75px">
              {intl.formatMessage({ id: "NOTIFY.DATE-OPTIONS.TITLE" })}:
            </span>

            {listTypeNotify ? (
              <div className="card-title w-100">
                {/* begin::Search */}
                <div className="d-flex align-items-center position-relative">
                  <TreeSelectC
                    type="type"
                    allItems={
                      listTypeNotify &&
                      listTypeNotify.map((i: any) => {
                        return {
                          ...i,
                          label: intl.formatMessage({ id: i.name }),
                        };
                      })
                    }
                    defaultIDs={[]}
                    changeAction={(ids: any) => {
                      updateState(ids, "type");
                    }}
                    updateState={updateState}
                    state={state}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </FilterHeader>
      <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.NOTIFICATIONS} />

      <KTCard>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
          <TableSummary cardList={cardList} />
          <div className="card-toolbar p-8">
            {/* begin::Group actions */}
            <UsersListToolbar handleRefetch={() => refetch()} />
            {/* end::Group actions */}
          </div>
        </div>

        <Table
          defineColumns={columns}
          data={users}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
          // className="frenzy"
        />
      </KTCard>
    </>
  );
};

const NoticePageWrapper = () => (
  <TableWrapper getPanigation={getPaginationListNotice}>
    <NoticePage />
  </TableWrapper>
);

export default NoticePageWrapper;
