// @ts-nocheck
import { toAbsoluteUrl } from "_metronic/helpers";
import UserCellPoint from "app/_components/point/UserCellPoint";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import clsx from "clsx";
import { Column } from "react-table";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { useIntl } from "react-intl";
import UserInfoCell from "app/_components/table/columns/UserInfoCell";

const usersColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PLATFORM"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "platform",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <PlatformCell user={row} />;
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="OS"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "device_os",
    accessor: "device_os",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <CellOS row={row} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USER"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "user",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <UserInfoCell user={row} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="POINT"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "score",
    accessor: "score",
    isCenterRow: true,
    Cell: ({ ...props }) => <UserCellPoint row={props?.row?.original} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="RANK"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "rank",
    accessor: "rank",
    isCenterRow: true,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTIVE.USERS-SHARE.COUNT"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "share",
    accessor: "share",
    isCenterRow: true,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="REGISTER-DATE"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
        isForceDesc={true}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "created_at",
    accessor: "created_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.created_at;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="UPDATED-AT"
        className="min-w-125px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "updated_at",
    accessor: "updated_at",
    Cell: ({ ...props }) => {
      const date = props?.row?.original.created_at;
      return <DateTimeCell date={date} />;
    },
  },
];

const PlatformCell = ({ user }) => {
  let imageSrc;
  switch (user?.platform) {
    case "amazon":
      imageSrc = toAbsoluteUrl("/media/social_media/amazon.svg");
      break;

    case "apple":
    case "firebase_apple":
      imageSrc = toAbsoluteUrl("/media/social_media/apple.svg");
      break;

    case "facebook":
      imageSrc = toAbsoluteUrl("/media/social_media/facebook.svg");
      break;

    case "google":
      imageSrc = toAbsoluteUrl("/media/social_media/google.svg");
      break;

    case "kakao":
      imageSrc = toAbsoluteUrl("/media/social_media/kakao.svg");
      break;

    case "naver":
      imageSrc = toAbsoluteUrl("/media/social_media/naver.svg");
      break;

    default:
      imageSrc = toAbsoluteUrl("/media/social_media/wishnote.png");
  }

  return (
    <div className="d-flex align-items-center">
      {/* begin:: Avatar */}
      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
        <div className={clsx("symbol-label fs-3")}>
          <img src={imageSrc} className="w-50 rounded-circle" />
        </div>
      </div>
    </div>
  );
};

const CellOS = ({ row }) => {
  const intl = useIntl();
  const data = row.device_os && row.device_os.split(",");
  const convertData =
    data &&
    data.length > 1 &&
    data.map((item) => intl.formatMessage({ id: `${item?.toUpperCase()}` }));

  return (
    <div>
      {row.device_os
        ? convertData.length > 0
          ? convertData.toString()
          : intl.formatMessage({ id: `${row.device_os?.toUpperCase()}` })
        : ""}
    </div>
  );
};

export { usersColumns };
