/* eslint-disable jsx-a11y/anchor-is-valid */
import { getCSSVariableValue } from "_metronic/assets/ts/_utils";
import NumberDelay from "app/_components/NumberDelay";
import React, { useEffect, useRef } from "react";
import ApexCharts from "react-apexcharts";
import { useIntl } from "react-intl";
import {
  useQueryRealTimeUserActiveReportResponseData,
  useQueryResponse,
} from "./core/QueryResponseProvider";
import { RealTimeChart } from "./core/_models";

type Props = {
  className: string;
  isSummary?: boolean;
};

const RealtimeActiveUsersChart: React.FC<Props> = ({
  className,
  isSummary = false,
}) => {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");

  const baseLightColor = getCSSVariableValue("--bs-success-light");
  const secondaryLightColor = getCSSVariableValue("--bs-warning-light");

  const chartRef = useRef<HTMLDivElement | null>(null);

  const intl = useIntl();
  const res = useQueryRealTimeUserActiveReportResponseData();
  const { refetchRealTimeUserActiveReport, isLoadingRealTimeChart } =
    useQueryResponse();

  const [data, setData] = React.useState<RealTimeChart[]>([]);
  const x = data.map((item, index) => item.dtm.split(" ")[1]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchRealTimeUserActiveReport();
    }, 20000); // Update every second

    return () => clearInterval(interval);
  }, [res]);

  useEffect(() => {
    // if (res && data.length <= 0) {
    setData(res.reverse());
    // }
  }, [res]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const newDataPoint = res[0];

  //     // Limit the number of data points to, for example, the last 10
  //     if (
  //       newDataPoint?.dtm !==
  //       (data && data[data.length - 1] && data[data.length - 1].dtm)
  //     ) {
  //       if (data.length >= 7) {
  //         setData((prevData) => prevData.slice(1));
  //       }

  //       setData((prevData) => [...prevData, newDataPoint]);
  //     }
  //   }, 5000); // Update every second

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [res]);

  useEffect(() => {
    return () => {
      setData([]);
    };
  }, []);

  const options: any = {
    chart: {
      toolbar: {
        // Hamburger menu at top
        show: false,
      },
      height: 200,
      zoom: {
        enabled: false,
      },
    },

    dataLabels: {
      enabled: false,
      enabledOnSeries: [0],
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    title: {
      text: undefined,
      align: "left",
    },

    legend: {
      show: false,
    },
    xaxis: {
      categories: x,
      tickAmount: 4,
      show: true,
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },

        rotate: 0,
      },
      tooltip: {
        enabled: false,
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
      },
    },
    yaxis: {
      tickAmount: 3,
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
        formatter: function (val: any) {
          return val?.toFixed(0);
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      min: 0,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: "14%",
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val: any) {
          return val + "";
        },
      },
      marker: {
        show: true,
      },
    },
    fill: {
      //type: ["solid", "solid", "gradient"],
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.65,
        stops: [1000, 100, 100, 100],
      },
    },

    markers: {
      colors: ["rgb(0, 143, 251)", baseLightColor, secondaryLightColor],
      strokeColors: [secondaryLightColor, baseLightColor],
      strokeWidth: 3,
    },

    colors: ["#219ebc", "rgb(7, 172, 255)", "#8ecae6"],
  };

  const lastItem = data[data?.length - 1];

  return (
    <div className={`card ${className}`} style={{ backgroundColor: "#f8fdff" }}>
      {/* begin::Beader */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {intl.formatMessage({
              id: "OVERVIEW.USERS.REALTIME.TITLE",
            })}{" "}
          </span>

          <span className="text-muted fw-semibold fs-7">
            {intl.formatMessage({ id: "OVERVIEW.USERS.DESCRIPTION" })}
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body p-0 d-flex flex-column">
        {/* begin::Stats */}
        <div
          className="card-p pt-5  flex-grow-1"
          style={{ backgroundColor: "rgb(238 247 255)", borderRadius: 10 }}
        >
          <div className="d-flex justify-content-around">
            <div>
              <div className="fw-semibold fs-7 text-muted">
                {" "}
                {intl.formatMessage({
                  id: "OVERVIEW.MEMBERS.ANDROID",
                })}
              </div>
              {/* end::Label */}
              {/* begin::Stat */}
              <div>
                <div
                  className="fs-4 fw-bold"
                  style={{ color: "rgb(33, 158, 188)" }}
                >
                  {lastItem && lastItem.android_total ? (
                    <NumberDelay number={lastItem.android_total} />
                  ) : isLoadingRealTimeChart ? (
                    <div className="placeholder-glow" role="status">
                      <span className="placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px"></span>
                    </div>
                  ) : (
                    0
                  )}
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="fw-semibold fs-7 text-muted">
                  {" "}
                  {intl.formatMessage({
                    id: "OVERVIEW.MEMBERS.IOS",
                  })}
                </div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div
                    className="fs-4 fw-bold"
                    style={{ color: "rgb(7, 172, 255)" }}
                  >
                    {(lastItem && lastItem.ios_total) ||
                    (lastItem && lastItem.ios_total === 0) ? (
                      <NumberDelay number={lastItem.ios_total} />
                    ) : isLoadingRealTimeChart ? (
                      <div className="placeholder-glow" role="status">
                        <span className="placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px"></span>
                      </div>
                    ) : (
                      0
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div className="fw-semibold fs-7 text-muted">
                  {" "}
                  {intl.formatMessage({
                    id: "OVERVIEW.MEMBERS.TOTAL_USER",
                  })}
                </div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div
                    className="fs-4 fw-bold"
                    style={{ color: "rgb(142, 202, 230)" }}
                  >
                    {lastItem && lastItem.user_total ? (
                      <NumberDelay number={lastItem.user_total} />
                    ) : isLoadingRealTimeChart ? (
                      <div className="placeholder-glow" role="status">
                        <span className="placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px"></span>
                      </div>
                    ) : (
                      0
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div className="w-100 h-200px">
          <div
            ref={chartRef}
            className="mixed-widget-7-chart card-rounded-bottom"
          >
            {data.length > 0 ? (
              <div
                ref={chartRef}
                id="kt_charts_widget_3_chart"
                className="d-flex justify-content-center align-items-center"
              >
                <div className="realtime-line-chart w-100">
                  <ApexCharts
                    options={options as any}
                    series={[
                      {
                        name: intl.formatMessage({
                          id: "OVERVIEW.MEMBERS.ANDROID",
                        }),
                        type: "area",
                        data: data.map((item) => item.android_total),
                      },
                      {
                        name: intl.formatMessage({
                          id: "OVERVIEW.MEMBERS.IOS",
                        }),
                        type: "area",
                        data: data.map((item) => item.ios_total),
                      },
                      {
                        name: intl.formatMessage({
                          id: "OVERVIEW.MEMBERS.TOTAL_USER",
                        }),
                        type: "bar",
                        data: data.map((item) => item.user_total),
                      },
                    ]}
                    width={"100%"}
                    height={200}
                  />
                </div>
              </div>
            ) : null}
          </div>
          {/* {isLoadingRealTimeChart ? (
                <div className="position-absolute z-index-1 d-flex w-100 h-300px top-0 start-0 bg-light d-flex align-items-center justify-content-center bg-opacity-75">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : null} */}
        </div>

        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );

  return (
    <>
      {/* {isSummary ? (
        <RealtimeActiveUser className="" />
      ) : ( */}
      <div
        className={`card ${className}`}
        style={{ backgroundColor: "rgb(243 251 255)" }}
      >
        <div className="card-body d-flex flex-column p-0">
          {/* begin::Stats */}
          <div
            className={`pt-2 card-rounded h-150px w-100`}
            style={{ backgroundColor: "#076591", borderRadius: 10 }}
          >
            <div className="card-header border-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1 text-white">
                  {intl.formatMessage({
                    id: "OVERVIEW.USERS.REALTIME.TITLE",
                  })}
                </span>

                <span className="text-muted fw-semibold fs-7">
                  {intl.formatMessage({
                    id: "OVERVIEW.USERS.REALTIME.DESCRIPTION",
                  })}
                </span>
              </h3>
            </div>
            {/* end::Stats */}
            <div className="d-flex justify-content-around">
              <div className="d-flex text-center flex-column text-white">
                <span className="fw-semibold fs-7">
                  {intl.formatMessage({
                    id: "OVERVIEW.MEMBERS.ANDROID",
                  })}
                </span>
                <span
                  className="fw-bold fs-1 pt-1"
                  style={{ color: "#219ebc" }}
                >
                  {lastItem && lastItem.android_total ? (
                    <NumberDelay number={lastItem.android_total} />
                  ) : isLoadingRealTimeChart ? (
                    <div className="placeholder-glow" role="status">
                      <span className="placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px"></span>
                    </div>
                  ) : (
                    0
                  )}
                </span>
              </div>
              <div className="d-flex text-center flex-column text-white">
                <span className="fw-semibold fs-7">
                  {intl.formatMessage({
                    id: "OVERVIEW.MEMBERS.IOS",
                  })}
                </span>
                <span
                  className="fw-bold fs-1 pt-1"
                  style={{ color: "rgb(7 172 255)" }}
                >
                  {(lastItem && lastItem.ios_total) ||
                  (lastItem && lastItem.ios_total === 0) ? (
                    <NumberDelay number={lastItem.ios_total} />
                  ) : isLoadingRealTimeChart ? (
                    <div className="placeholder-glow" role="status">
                      <span className="placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px"></span>
                    </div>
                  ) : (
                    0
                  )}
                </span>
              </div>
              <div className="d-flex text-center flex-column text-white">
                <span className="fw-semibold fs-7">
                  {intl.formatMessage({
                    id: "OVERVIEW.MEMBERS.TOTAL_USER",
                  })}
                </span>
                <span
                  className="fw-bold fs-1 pt-1"
                  style={{ color: "#8ecae6" }}
                >
                  {lastItem && lastItem.user_total ? (
                    <NumberDelay number={lastItem.user_total} />
                  ) : isLoadingRealTimeChart ? (
                    <div className="placeholder-glow" role="status">
                      <span className="placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px"></span>
                    </div>
                  ) : (
                    0
                  )}
                </span>
              </div>
            </div>
          </div>

          {/* begin::Chart */}

          <div className="w-100 h-200px pt-8">
            <div
              ref={chartRef}
              className="mixed-widget-7-chart card-rounded-bottom"
            >
              {data.length > 0 ? (
                <div
                  ref={chartRef}
                  id="kt_charts_widget_3_chart"
                  className="d-flex justify-content-center align-items-center"
                >
                  <div className="realtime-line-chart w-100">
                    <ApexCharts
                      options={options as any}
                      series={[
                        {
                          name: intl.formatMessage({
                            id: "OVERVIEW.MEMBERS.ANDROID",
                          }),
                          type: "area",
                          data: data.map((item) => item.android_total),
                        },
                        {
                          name: intl.formatMessage({
                            id: "OVERVIEW.MEMBERS.IOS",
                          }),
                          type: "area",
                          data: data.map((item) => item.ios_total),
                        },
                        {
                          name: intl.formatMessage({
                            id: "OVERVIEW.MEMBERS.TOTAL_USER",
                          }),
                          type: "bar",
                          data: data.map((item) => item.user_total),
                        },
                      ]}
                      width={"100%"}
                      height={200}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {/* {isLoadingRealTimeChart ? (
                <div className="position-absolute z-index-1 d-flex w-100 h-300px top-0 start-0 bg-light d-flex align-items-center justify-content-center bg-opacity-75">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : null} */}
          </div>

          {/* end::Chart */}
        </div>
      </div>
      {/* )} */}
    </>
  );
};

const RealtimeActiveUsersChartWrapper = ({ className, isSummary }: any) => (
  <RealtimeActiveUsersChart className={className} isSummary={isSummary} />
);

export { RealtimeActiveUsersChartWrapper };
