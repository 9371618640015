import { useParams } from "react-router-dom";
import TableWrapper from "app/_components/coreListData";
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { KTCard } from "_metronic/helpers";
import { Table } from "app/_components/table/Table";
import { _listPostsOfUserColumn } from "../table/columns/_listPostsOfUserColumn";
import { useMemo } from "react";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { getPaginationPosts } from "../core/_requests";

const ListPostUserProfile = () => {
  const users = useQueryResponseData();

  const data = useMemo(() => users, [users]);

  return (
    <div>
      <KTCard>
        <Table
          defineColumns={_listPostsOfUserColumn}
          data={data}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
        />
      </KTCard>
    </div>
  );
};

const ListPostUserProfileWrapper = () => {
  const params = useParams();
  return (
    <TableWrapper
      params={params.id}
      getPanigation={(query) => getPaginationPosts(query, params.id)}
    >
      <ListPostUserProfile />
    </TableWrapper>
  );
};

export default ListPostUserProfileWrapper;
