// @ts-nocheck
import { subString, toAbsoluteUrl } from "_metronic/helpers";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Column } from "react-table";

import { notification, Popconfirm } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import { KTIcon } from "_metronic/helpers";
import { deleteNotice } from "../_requests";
import TooltipWrapper from "app/_components/TooltipWrapper";

const usersColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ID"
        className="min-w-50px text-start"
        v
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "id",
    accessor: "id",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="TITLE"
        className="min-w-50px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "title",
    accessor: "title",
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      const lang = JSON.parse(localStorage.getItem("i18nConfig"));
      try {
        const arrayTitle = JSON.parse(item?.title) || [];
        if (lang && typeof arrayTitle === "object") {
          const existData = arrayTitle.find(
            (item: any) => item?.lang === lang?.selectedLang
          );
          if (existData) {
            return <p className="mb-0">{existData.text}</p>;
          } else {
            return null;
          }
        }
      } catch (error) {
        if (typeof item?.title == "string") {
          return <p className="mb-0">{item?.title}</p>;
        }
        return null;
      }
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="CONTENT"
        className="min-w-50px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "body",
    accessor: "body",
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      const lang = JSON.parse(localStorage.getItem("i18nConfig"));
      try {
        const arrayTitle = JSON.parse(item?.body) || [];
        if (lang && typeof arrayTitle === "object") {
          const existData = arrayTitle.find(
            (item: any) => item?.lang === lang?.selectedLang
          );
          if (existData) {
            return (
              <TooltipWrapper placement="top" text={existData.text} limit={100}>
                <p className="mb-0">{subString(existData.text, 100)}</p>
              </TooltipWrapper>
            );
          } else {
            return null;
          }
        }
      } catch (error) {
        if (typeof item?.body == "string") {
          return (
            <TooltipWrapper placement="top" text={item?.body} limit={100}>
              <p className="mb-0">{subString(item?.body, 100)}</p>
            </TooltipWrapper>
          );
        }
        return null;
      }
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PUSH-STATUS"
        className="min-w-100x text-center"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "push_status",
    accessor: "push_status",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      return <Status item={item} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="DEVICE"
        className="min-w-100x"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "device_type",
    accessor: "device_type",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const item = props?.row?.original;
      return <DeviceCell item={item} />;
    },
  },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title="LOOP"
  //       className="min-w-50px"
  //       useQueryRequest={useQueryRequest}
  //     />
  //   ),
  //   id: "is_loop",
  //   accessor: "is_loop",
  // },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PUSH-AT"
        className="min-w-100x"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "push_at",
    accessor: "push_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.push_at;
      return <DateTimeCell date={date} />;
    },
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title="RESERVATION-AT"
  //       className="min-w-100px"
  //       useQueryRequest={useQueryRequest}
  //       isNoSort={false}
  //       isCenter={true}
  //       isForceDesc={true}
  //     />
  //   ),
  //   id: "reservation_at",
  //   accessor: "reservation_at",
  //   isCenterRow: true,
  //   Cell: ({ ...props }) => {
  //     const date = props?.row?.original.reservation_at;
  //     return <DateTimeCell date={date} />;
  //   },
  // },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="DELETE"
        className=""
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action-delete",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const intl = useIntl();
      const row = props?.row?.original;
      if (row?.is_deleted) return <></>;
      return <DeleteComponent row={row} />;
    },
  },
];

const Status = ({ item }: any) => {
  const intl = useIntl();
  let badgeColor = "success";
  let label = "NOTIFY.STATUS.COMPLETED";
  // if (item.push_status === "processing") {
  //   badgeColor = "primary";
  //   label = "NOTIFY.STATUS.PROCESSING";
  // }
  if (item.push_status === "waiting") {
    badgeColor = "warning";
    label = "NOTIFY.STATUS.WAITING";
  }

  return (
    <span
      className={`badge rounded-pill m-auto badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}
    >
      {intl.formatMessage({ id: label })}
    </span>
  );
};

const DeviceCell = ({ item }: any) => {
  const intl = useIntl();

  return (
    <span>{intl.formatMessage({ id: item.device_type.toUpperCase() })}</span>
  );
};

function DeleteComponent({ row }) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Popconfirm
        title={intl.formatMessage({ id: "DELETE" })}
        description={intl.formatMessage({ id: "NOTICE.DELETE.CONFIRM" })}
        onConfirm={async () => {
          const res = await deleteNotice(row.id);
          if (res.code == 200) {
            notification.success({
              message: intl.formatMessage({ id: "DELETE-SUCCESS" }),
              duration: 3,
              //style: { backgroundColor: "#f1416c", color: "white" },
            });
            refetch();
          }
        }}
        onCancel={() => {}}
        okText={intl.formatMessage({ id: "YES" })}
        cancelText={intl.formatMessage({ id: "NO" })}
      >
        <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
          <KTIcon iconName="trash" className="fs-2" />
        </button>
      </Popconfirm>
    </div>
  );
}

export { usersColumns };
