/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLang } from "_metronic/i18n/Metronici18n";
import ApexCharts, { ApexOptions } from "apexcharts";
import React, { useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import {
  useQueryContentsReportResponseData,
  useQueryResponse,
} from "./core/QueryResponseProvider";
import NumberDelay from "app/_components/NumberDelay";

type Props = {
  className: string;
  isSummary?: boolean;
};

const PostUploadChart: React.FC<Props> = ({ className, isSummary = false }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  const { queryFilterChart } = useQueryResponse();
  const { isLoadingVideosChart } = useQueryResponse();

  const [typeOfChart, setTypeOfChart] = React.useState("week");

  const intl = useIntl();

  const lang = useLang();

  const refreshMode = (result: any) => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(200, result, x, typeOfChart, lang, isSummary)
    );

    if (chart) {
      chart.render();
      // window.dispatchEvent(new Event("resize"));
    }

    return chart;
  };

  const data = useQueryContentsReportResponseData();

  const x =
    typeOfChart === "week"
      ? data.map((obj) => {
          const dataItem = `${obj.date?.substring(5, 7)}/${obj.date?.substring(
            8,
            10
          )}/${obj.date?.substring(0, 4)}`;
          return dataItem || 0;
        })
      : data.map((obj) => {
          if (obj.date) {
            const hour = parseInt(obj.date.substring(11, 13));
            return hour + "h";
          }
          return 0 + "h";
        });

  const boardData = useMemo(() => {
    return data.reduce(
      (init: any, obj: any) => {
        if (typeof obj.wish_board === "number") {
          init["wishBoard"] = {
            ...init["wishBoard"],
            data: [...init?.wishBoard?.data, obj?.wish_board],
          };
        }
        if (typeof obj.vision_board === "number") {
          init["visionBoard"] = {
            ...init["visionBoard"],
            data: [...init?.visionBoard?.data, obj.vision_board],
          };
        }
        if (typeof obj.daily_count === "number") {
          init.dailyBoard = {
            ...init["dailyBoard"],
            data: [...init?.dailyBoard?.data, obj.daily_count],
          };
        }
        if (typeof obj.good_content_count === "number") {
          init.goodContentBoard = {
            ...init["goodContentBoard"],
            data: [...init?.goodContentBoard?.data, obj.good_content_count],
          };
        }

        return init;
      },
      {
        wishBoard: { name: intl.formatMessage({ id: "GOAL" }), data: [] },
        visionBoard: {
          name: intl.formatMessage({ id: "WISHBOARD" }),
          data: [],
        },
        dailyBoard: { name: intl.formatMessage({ id: "DAILY" }), data: [] },
        goodContentBoard: {
          name: intl.formatMessage({ id: "GOOD_CONTENT" }),
          data: [],
        },
      }
    );
  }, [data, intl]);

  const result = Object.values(boardData);

  useEffect(() => {
    let chart: any = null;
    chart = refreshMode(result);
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, result]);

  useEffect(() => {
    if (queryFilterChart) {
      if (
        (queryFilterChart as any)?.start_date ===
        (queryFilterChart as any)?.end_date
      ) {
        setTypeOfChart("day");
      } else {
        setTypeOfChart("week");
      }
    }
  }, [queryFilterChart]);

  const sumData = (numbers: number[]) => {
    return (numbers || []).reduce((sum, number) => sum + number, 0);
  };

  return (
    <div className={`card ${className}`} style={{ backgroundColor: "#f8fdff" }}>
      {/* begin::Beader */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {intl.formatMessage({ id: "OVERVIEW.CONTENTS.TITLE" })}
          </span>

          <span className="text-muted fw-semibold fs-7">
            {intl.formatMessage({ id: "OVERVIEW.CONTENTS.DESCRIPTION" })}
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body p-0 d-flex flex-column">
        {/* begin::Stats */}
        <div
          className="card-p pt-5  flex-grow-1"
          style={{ backgroundColor: "#e2ecf5", borderRadius: 10 }}
        >
          <div className="d-flex justify-content-around">
            <div>
              <div className=" text-muted fw-semibold">
                {intl.formatMessage({ id: "GOAL" })}
              </div>
              {/* end::Label */}
              {/* begin::Stat */}
              <div>
                <div className="fs-4 fw-bold" style={{ color: "#010e36" }}>
                  <NumberDelay number={sumData(boardData?.wishBoard.data)} />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className=" text-muted fw-semibold">
                  {intl.formatMessage({ id: "WISHBOARD" })}
                </div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div className="fs-4 fw-bold" style={{ color: "#2177cf" }}>
                    <NumberDelay
                      number={sumData(boardData?.visionBoard.data)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className=" text-muted fw-semibold">
                  {intl.formatMessage({ id: "DAILY" })}
                </div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div className="fs-4 fw-bold" style={{ color: "#65cde7" }}>
                    <NumberDelay number={sumData(boardData?.dailyBoard.data)} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className=" text-muted fw-semibold">
                  {intl.formatMessage({ id: "GOOD_CONTENT" })}
                </div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div className="fs-4 fw-bold" style={{ color: "	#9cd8c0" }}>
                    <NumberDelay
                      number={sumData(boardData?.goodContentBoard.data)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div className="w-100 h-200px">
          {data.length > 0 ? (
            <div>
              {/* begin::Chart */}
              <div
                ref={chartRef}
                className={`mixed-widget-3-chart card-rounded-bottom ${
                  isLoadingVideosChart ? "h-150px" : ""
                }`}
                // data-kt-chart-color={chartColor}
                // style={{ height: chartHeight }}
              ></div>
            </div>
          ) : null}
          {isLoadingVideosChart ? (
            <div className="position-absolute z-index-1 d-flex w-100 h-400px top-0 start-0 bg-light d-flex align-items-center justify-content-center bg-opacity-75">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null}
        </div>

        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

const PostUploadChartWrapper = ({ className, isSummary }: any) => (
  <PostUploadChart className={className} isSummary={isSummary} />
);

export default PostUploadChartWrapper;

function getChartOptions(
  height: number,
  result: any,
  x: any,
  typeOfChart: string,
  lang: string,
  isSummary: boolean
): ApexOptions {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");

  const baseColor = "rgb(0,67,157)";
  const secondaryColor = "grey";
  return {
    series: result,
    chart: {
      type: "bar",
      height: height,
      toolbar: {
        show: false,
        tools: {
          download: true,
        },
      },

      // background: "linear-gradient(195deg, #42424a, #191919)",
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shadeIntensity: 1,
    //     inverseColors: false,
    //     opacityFrom: 0.45,
    //     opacityTo: 0.05,
    //     stops: [20, 100, 100, 100],
    //   },
    // },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        //columnWidth: x.length > 29 ? "100%" : x.length > 7 ? "60%" : "20%",

        columnWidth:
          x && x.length > 29
            ? "80%"
            : x && x.length > 14
            ? "40%"
            : x && x.length > 7
            ? "40%"
            : x && x.length > 3
            ? "15%"
            : "10%",
      },
    },
    responsive: [
      {
        breakpoint: 1280,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],
    grid: {
      show: true,
      borderColor: "#F4F6FA",
      strokeDashArray: 6,
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 25,
      },
    },
    xaxis: {
      categories: x,
      tickAmount: 4,
      labels: {
        style: {
          fontSize: "12px",
          colors: labelColor,
        },
        rotate: 0,
        formatter(value, opts) {
          return typeOfChart === "week"
            ? new Date(value).toLocaleDateString(
                lang === "en" ? "en-us" : "ko-KR",
                {
                  month: "short",
                  day: "2-digit",
                }
              )
            : value;
        },
      },
    },
    yaxis: {
      tickAmount: 3,
      forceNiceScale: true,
      labels: {
        style: {
          colors: labelColor,
        },
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return "" + val;
        },
      },
    },
    colors: ["#010e36", "#2177cf", "#65cde7", "#9cd8c0"],

    legend: {
      show: false,
    },
  };
}
