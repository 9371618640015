import { ListSearchComponent } from "app/_components/table/header/ListSearchComponent";
import { useQueryRequest } from "../../core/QueryRequestProvider";

interface SearchCustomProps {
  placeholder: string;
  key: number;
  state: any;
  updateState: (value: any) => void;
}
export default function SearchCustom({
  placeholder,
  state,
  updateState,
}: SearchCustomProps) {
  return (
    <div className="card-toolbar align-items-end min-w-100 min-w-sm-250px">
      <ListSearchComponent
        state={state}
        updateState={updateState}
        useQueryRequest={useQueryRequest}
        placeholder={placeholder}
      />
    </div>
  );
}
