/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLang } from "_metronic/i18n/Metronici18n";
import ApexCharts, { ApexOptions } from "apexcharts";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import {
  useQueryMultiUserActiveReportResponse,
  useQueryMultiUserActiveReportResponseData,
} from "./core/QueryResponseProvider";
import NumberDelay from "app/_components/NumberDelay";

type Props = {
  className: string;
  isPageMode?: boolean;
  isSummaryPage?: boolean;
};

const ActiveUserChart: React.FC<Props> = ({
  className,
  isPageMode = false,
  isSummaryPage = false,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const intl = useIntl();
  const [chart, setChart] = useState<ApexCharts | null>(null);

  const refreshMode = (data: any) => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(200, formatData(), lang, intl, isSummaryPage)
    );

    if (chart) {
      chart.render();
    }

    return chart;
  };

  const data = useQueryMultiUserActiveReportResponseData();
  const { isLoadingMultiUserActiveReport } =
    useQueryMultiUserActiveReportResponse();

  const dataLine1 = data.map((item: any) => ({
    x: item.date,
    y: item.dau,
  }));
  const dataLine2 = data.map((item: any) => ({
    x: item.date,
    y: item.wau,
  }));
  const dataLine3 = data.map((item: any) => ({
    x: item.date,
    y: item.mau,
  }));

  const formatData = () => {
    if (
      dataLine1.length <= 0 &&
      dataLine2.length <= 0 &&
      dataLine3.length <= 0
    ) {
      return [];
    }
    return [dataLine1, dataLine2, dataLine3];
  };

  const lang = useLang();

  useEffect(() => {
    let chart: any = null;
    chart = refreshMode(formatData);

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, data]);

  const lastItem = dataLine1[dataLine1.length - 1];

  return (
    <div className={`card ${className}`} style={{ backgroundColor: "#f8fdff" }}>
      {/* begin::Beader */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {intl.formatMessage({ id: "OVERVIEW.USERS.TITLE" })}
          </span>

          <span className="text-muted fw-semibold fs-7">
            {intl.formatMessage({ id: "OVERVIEW.USERS.DESCRIPTION" })}
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body p-0 d-flex flex-column">
        {/* begin::Stats */}
        <div
          className="card-p pt-5  flex-grow-1"
          style={{ backgroundColor: "rgb(238 247 255)", borderRadius: 10 }}
        >
          <div className="d-flex justify-content-around">
            <div>
              <div className="fw-semibold fs-7 text-muted">DAU</div>
              {/* end::Label */}
              {/* begin::Stat */}
              <div>
                <div
                  className="fs-4 fw-bold"
                  style={{ color: "rgb(7, 172, 255)" }}
                >
                  {dataLine1[dataLine1.length - 1]?.y ? (
                    <NumberDelay number={dataLine1[dataLine1.length - 1]?.y} />
                  ) : isLoadingMultiUserActiveReport ? (
                    <div className="placeholder-glow" role="status">
                      <span className="placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px"></span>
                    </div>
                  ) : (
                    "_"
                  )}{" "}
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="fw-semibold fs-7 text-muted">WAU</div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div className="fs-4 fw-bold" style={{ color: "#219ebc" }}>
                    {dataLine2[dataLine2.length - 1]?.y ? (
                      <NumberDelay
                        number={dataLine2[dataLine2.length - 1]?.y}
                      />
                    ) : isLoadingMultiUserActiveReport ? (
                      <div className="placeholder-glow" role="status">
                        <span className="placeholder placeholder-loading bg-#00e396 w-15px h-3px min-h-3px"></span>
                      </div>
                    ) : (
                      "_"
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div className="fw-semibold fs-7 text-muted">MAU</div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div className="fs-4 fw-bold" style={{ color: "#8ecae6" }}>
                    {dataLine3[dataLine3.length - 1]?.y ? (
                      <NumberDelay
                        number={dataLine3[dataLine3.length - 1]?.y}
                      />
                    ) : isLoadingMultiUserActiveReport ? (
                      <div className="placeholder-glow" role="status">
                        <span className="placeholder placeholder-loading bg-#008ffb w-15px h-3px min-h-3px"></span>
                      </div>
                    ) : (
                      "_"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div className="w-100 h-200px">
          {!isLoadingMultiUserActiveReport && data.length > 0 ? (
            <div>
              {/* begin::Chart */}
              <div
                ref={chartRef}
                className={`mixed-widget-3-chart card-rounded-bottom ${
                  isLoadingMultiUserActiveReport ? "h-150px" : ""
                }`}
                // data-kt-chart-color={chartColor}
                // style={{ height: chartHeight }}
              ></div>
            </div>
          ) : null}
          {isLoadingMultiUserActiveReport ? (
            <div className="position-absolute z-index-1 d-flex w-100 h-100 top-0 start-0 bg-light d-flex align-items-center justify-content-center bg-opacity-75">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null}
        </div>

        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );

  return (
    <div
      className={`card ${className}`}
      style={{ backgroundColor: "rgb(243 251 255)" }}
    >
      {/* begin::Body */}
      <div className="card-body p-0">
        {/* begin::Header */}
        <div
          className={`pt-2 card-rounded h-150px w-100`}
          style={{ backgroundColor: "#076591" }}
        >
          {/* begin::Heading */}
          <div className="card-header border-0">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1 text-white">
                {intl.formatMessage({ id: "OVERVIEW.USERS.TITLE" })}
              </span>

              <span className="text-muted fw-semibold fs-7">
                {intl.formatMessage({ id: "OVERVIEW.USERS.DESCRIPTION" })}
              </span>
            </h3>
          </div>

          <div className="d-flex justify-content-around">
            <div className="d-flex text-center flex-column text-white">
              <span className="fw-semibold fs-7">{"DAU"}</span>
              <span
                className="fw-bold fs-1 pt-1"
                style={{ color: "rgb(7 172 255)" }}
              >
                {dataLine1[dataLine1.length - 1]?.y ? (
                  <NumberDelay number={dataLine1[dataLine1.length - 1]?.y} />
                ) : isLoadingMultiUserActiveReport ? (
                  <div className="placeholder-glow" role="status">
                    <span className="placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px"></span>
                  </div>
                ) : (
                  "_"
                )}
              </span>
            </div>

            <div className="d-flex text-center flex-column text-white">
              <span className="fw-semibold fs-7">{"WAU"}</span>
              <span className="fw-bold fs-1 pt-1" style={{ color: "#219ebc" }}>
                {dataLine2[dataLine2.length - 1]?.y ? (
                  <NumberDelay number={dataLine2[dataLine2.length - 1]?.y} />
                ) : isLoadingMultiUserActiveReport ? (
                  <div className="placeholder-glow" role="status">
                    <span className="placeholder placeholder-loading bg-#00e396 w-15px h-3px min-h-3px"></span>
                  </div>
                ) : (
                  "_"
                )}
              </span>
            </div>

            <div className="d-flex text-center flex-column text-white ">
              <span className="fw-semibold fs-7">{"MAU"}</span>
              <span className="fw-bold fs-1 pt-1" style={{ color: "#8ecae6" }}>
                {dataLine3[dataLine3.length - 1]?.y ? (
                  <NumberDelay number={dataLine3[dataLine3.length - 1]?.y} />
                ) : isLoadingMultiUserActiveReport ? (
                  <div className="placeholder-glow" role="status">
                    <span className="placeholder placeholder-loading bg-#008ffb w-15px h-3px min-h-3px"></span>
                  </div>
                ) : (
                  "_"
                )}
              </span>
            </div>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className="position-relative h-250px pt-8"
          // className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body"
          // style={{ marginTop: "-100px" }}
        >
          {isLoadingMultiUserActiveReport ? (
            <div className="position-absolute z-index-1 d-flex w-100 h-100 top-0 start-0 bg-light d-flex align-items-center justify-content-center bg-opacity-75">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null}
          {/* {data && data.length > 0 ? ( */}

          {!isLoadingMultiUserActiveReport && data.length > 0 ? (
            <div
              ref={chartRef}
              id="kt_charts_widget_3_chart"
              className={`w-100 ${
                isLoadingMultiUserActiveReport ? "h-250px" : ""
              } ${!isSummaryPage ? "rounded" : ""}`}
            ></div>
          ) : null}

          {/* ) : null} */}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  );
};

const ActiveUserChartWrapper = ({
  className,
  isPageMode,
  isSummaryPage,
}: any) => (
  <ActiveUserChart
    className={className}
    isPageMode={isPageMode}
    isSummaryPage={isSummaryPage}
  />
);

export default ActiveUserChartWrapper;

function getChartOptions(
  height: number,
  formattedData: any,
  lang: any,
  intl: any,
  isSummaryPage = false
): ApexOptions {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");

  const baseLightColor = getCSSVariableValue("--bs-success-light");
  const secondaryLightColor = getCSSVariableValue("--bs-warning-light");

  return {
    series: [
      {
        type: "area",
        data: formattedData[0],
        name: intl.formatMessage({
          id: "DAU",
        }),
      },
      {
        type: "area",
        data: formattedData[1],
        name: intl.formatMessage({
          id: "WAU",
        }),
      },
      {
        type: "area",
        data: formattedData[2],
        name: intl.formatMessage({
          id: "MAU",
        }),
      },
    ],
    chart: {
      id: "user_active_chart",
      fontFamily: "inherit",
      type: "line",
      height: height,
      toolbar: {
        show: false,
        tools: {
          download: true,
          zoom: false,
          reset: false,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
    },
    // grid: {
    //   show: true,
    //   borderColor: "#F4F6FA",
    //   strokeDashArray: 6,
    // },
    responsive: [
      {
        breakpoint: 780,
        options: {
          chart: {
            height: 200,
          },
        },
      },
      {
        breakpoint: 1280,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],

    fill: {
      type: "solid",
      opacity: isSummaryPage ? 0.2 : 0.4,
    },

    stroke: {
      curve: "smooth",
      show: true,
      width: 1,
    },
    xaxis: {
      // type: "datetime",
      tickAmount: 4,

      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: false,
      },

      crosshairs: {
        position: "front",
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },

      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
        rotate: 0,

        formatter(value) {
          return new Date(value).toLocaleDateString(
            lang === "en" ? "en-US" : "ko-KR",
            {
              month: "short",
              day: "2-digit",
            }
          );
        },
      },
      tooltip: {
        enabled: false,
      },
    },

    yaxis: {
      tickAmount: 4,
      forceNiceScale: true,
      labels: {
        style: {
          colors: "gray",
          fontSize: "12px",
        },
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
      min: 0,
      decimalsInFloat: 0,
    },

    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return val + "";
        },
      },
    },

    legend: {
      show: false,
      labels: {
        colors: "gray",
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    colors: ["rgb(7, 172, 255)", "#219ebc", "#8ecae6"],
    markers: {
      colors: ["rgb(0, 143, 251)", baseLightColor, secondaryLightColor],
      strokeColors: [secondaryLightColor, baseLightColor],
      strokeWidth: 3,
    },
  };
}
