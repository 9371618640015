import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";

import { useEffect, useRef, useState } from "react";
import { UserRanking } from "../../core/_models";
import { getPaginationAllRankings } from "../core/_requests";
import { toAbsoluteUrl } from "_metronic/helpers";
import { Link } from "react-router-dom";
import ChipPoint from "app/_components/point/ChipPoint";
import EmptyChart from "_metronic/layout/components/empty/EmptyChart";

export const Overall = () => {
  const [data, setData] = useState<UserRanking[]>([]);
  const [pagination, setPagination] = useState<any>({
    items_per_page: 10,
    nextPage: 1,
  });
  const startIndexRef = useRef(0);

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = async (startIndex: number, endIndex?: number) => {
    try {
      if (
        !pagination.nextPage ||
        (endIndex && startIndexRef.current === startIndex)
      )
        return;

      startIndexRef.current = startIndex;

      const result = await getPaginationAllRankings({
        page: pagination.nextPage,
        limit: pagination.items_per_page,
      });

      if (result) {
        setData((prev) => [...prev, ...result.data]);

        const maxPage = Math.ceil(
          result.pagination.total / result.pagination.items_per_page
        );
        const nextPage =
          startIndex / 10 > maxPage ? 0 : pagination.nextPage + 1;

        setPagination((prev: any) => ({
          ...prev,
          ...result.pagination,
          nextPage,
        }));
      }
    } catch (error) {}
  };

  const loadingCpn = (style?: any) => {
    return (
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ ...style, minHeight: "80px" }}
      >
        <div className="d-flex align-items-center placeholder-glow w-100">
          <div className="me-5">
            <span
              style={{ marginRight: 18, width: "36px", height: "36px" }}
              className="placeholder bg-secondary rounded"
            ></span>
            <span
              style={{
                marginRight: 14,
                width: "50px",
                height: "50px",
                borderRadius: "50%",
              }}
              className="placeholder bg-secondary "
            ></span>
            <span className="placeholder bg-secondary rounded w-150px h-30px"></span>
          </div>
        </div>
        <div className="text-center me-10" style={{ width: "120px" }}>
          <span className="placeholder bg-secondary rounded-pill col-1 w-100 h-30px"></span>
        </div>
      </div>
    );
  };

  // Render an item or a loading indicator.
  const Item = ({ index, style }: any) => {
    if (!isItemLoaded(index)) {
      return loadingCpn(style);
    }

    const item = data[index];

    return (
      <div
        style={style}
        className="d-flex align-items-center justify-content-between"
      >
        <div className="d-flex align-items-center w-100">
          <div className="me-5">
            {item?.rank < 4 ? (
              <img
                src={toAbsoluteUrl(`/media/ranking/${item?.rank}.svg`)}
                className="h-75 align-self-end"
                alt=""
              />
            ) : (
              <div
                className="d-flex align-items-center justify-content-center text-center"
                style={{ width: 36, height: 36 }}
              >
                {item?.rank}
              </div>
            )}
          </div>
          <div className="symbol symbol-50px me-5">
            <span className="symbol-label bg-light rounded-circle overflow-hidden">
              <div
                className={`align-self-end my-auto ${
                  item.avatar ? "h-100 w-100" : "h-30px w-30px"
                }`}
                style={{
                  backgroundImage: `url(${
                    item.avatar ? item.avatar : "/media/svg/avatars/blank.svg"
                  })`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
              {/* <img
              src={toAbsoluteUrl(
                item.avatar
                  ? item.avatar
                  : "/media/svg/avatars/blank.svg"
              )}
              className="align-self-end my-auto "
              alt=""
              height={item.avatar ? "100%" : 30}
              width={item.avatar ? "100%" : 30}
            /> */}
            </span>
          </div>
          <div className="d-flex justify-content-start flex-column">
            <Link
              to={`/user-management/users/active/detail/${item.id}`}
              className="text-dark fw-bold text-hover-primary mb-1 fs-6"
            >
              {item.name}
            </Link>
            <span className="text-muted fw-semibold text-muted d-block fs-7">
              {item.email}
            </span>
          </div>
        </div>
        <div
          className={`text-center text-dark fw-bold text-hover-primary d-flex align-items-center fs-6 me-10`}
          style={{ width: "120px" }}
        >
          <ChipPoint point={item?.total_point || 0} />
        </div>
      </div>
    );
  };

  useEffect(() => {
    loadMoreItems(1);
  }, []);

  const hasNextPage = data.length < pagination.total;

  const itemCount = hasNextPage ? data.length + 1 : data.length;

  const isItemLoaded = (index = 0) => !hasNextPage || index < data.length;

  return (
    <div>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        itemCount={itemCount}
      >
        {({ onItemsRendered, ref }) => (
          <>
            <FixedSizeList
              ref={ref}
              height={650}
              width="100%"
              itemCount={itemCount}
              itemSize={80}
              onItemsRendered={onItemsRendered}
            >
              {Item}
            </FixedSizeList>
            {!pagination.total && (
              <div
                className="card-body position-absolute py-3 w-100 min-h-350px d-flex align-items-center"
                style={{ inset: "0" }}
              >
                <EmptyChart />
              </div>
            )}
          </>
        )}
      </InfiniteLoader>
    </div>
  );
};
