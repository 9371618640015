import { useIntl } from "react-intl";
import { useLocation, useNavigate, Link } from "react-router-dom";

export default function TabsProfileInfo({ user }: any) {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const lastestLoginLog = user?.user_login_logs?.slice(-1)[0];
  const hanldeChangeTab = (tab: string) => {
    navigate(`/user-management/users/active/detail/${user?.id}/${tab}`, {
      replace: true,
    });
  };

  return (
    <div className="d-flex overflow-auto px-6 mb-6 mt-0 pt-0">
      <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
        <li className="nav-item" onClick={() => hanldeChangeTab("post")}>
          <Link
            className={
              `nav-link text-active-primary me-6 ` +
              (location.pathname ===
                `/user-management/users/active/detail/${user?.id}/post` &&
                "active")
            }
            to={`.`}
          >
            {intl.formatMessage({ id: "ARTICLE" })}
          </Link>
        </li>
        <li className="nav-item" onClick={() => hanldeChangeTab("share")}>
          <Link
            className={
              `nav-link text-active-primary py-0 my-0 me-6 ` +
              (location.pathname ===
                `/user-management/users/active/detail/${user?.id}/share` &&
                "active")
            }
            to={`.`}
          >
            {intl.formatMessage({ id: "MENU.SHARE-MANAGEMENT" })}
          </Link>
        </li>
        <li className="nav-item" onClick={() => hanldeChangeTab("purchase")}>
          <Link
            className={
              `nav-link text-active-primary py-0 my-0 me-6 ` +
              (location.pathname ===
                `/user-management/users/active/detail/${user?.id}/purchase` &&
                "active")
            }
            to={`.`}
          >
            {intl.formatMessage({ id: "MENU.PURCHASE" })}
          </Link>
        </li>
        <li className="nav-item" onClick={() => hanldeChangeTab("logs")}>
          <Link
            className={
              `nav-link text-active-primary py-0 my-0 me-6 ` +
              (location.pathname ===
                `/user-management/users/active/detail/${user?.id}/logs` &&
                "active")
            }
            to={`.`}
          >
            {intl.formatMessage({ id: "MENU.LOGS" })}
          </Link>
        </li>
      </ul>
    </div>
  );
}
