/* eslint-disable jsx-a11y/anchor-is-valid */
import ToolTipRanking from "app/_components/tooltips/ToolTipRanking";
import React from "react";
import { useIntl } from "react-intl";
import { Overall } from "./top-user/components/overall";
import { Invitation } from "./top-user/components/invitation";

type Props = {
  className: string;
};

const TopUserTable: React.FC<Props> = ({ className }) => {
  const intl = useIntl();

  const [type, setType] = React.useState("OVERALL");

  return (
    <div className={`card {className} h-100`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1 d-flex">
            {intl.formatMessage({ id: "TOP-USERS" })}
            <ToolTipRanking />
          </span>

          <span className="text-muted fw-semibold fs-7">
            {" "}
            {intl.formatMessage({ id: "TOP-USERS-DES" })}
          </span>
        </h3>
        <div className="card-toolbar" data-kt-buttons="true">
          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
              type === "OVERALL" ? "active" : ""
            }`}
            id="kt_charts_widget_3_year_btn"
            onClick={() => setType("OVERALL")}
          >
            {intl.formatMessage({ id: "OVERALL" })}
          </a>

          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
              type === "INVITATION" ? "active" : ""
            }`}
            id="kt_charts_widget_3_month_btn"
            onClick={() => setType("INVITATION")}
          >
            {intl.formatMessage({ id: "INVITATION" })}
          </a>
        </div>
      </div>
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="min-h-450px mh-700px overflow-hidden">
          {/* begin::Table */}
          <div className="gs-0 gy-4 position-relative">
            {/* begin::Table head */}
            <div className="d-flex h-50px justify-content-between align-items-center fw-bold text-muted bg-light sticky-top">
              <div className="ps-4 min-w-150px rounded-start sticky-top">
                {" "}
                {intl.formatMessage({ id: "USER" })}
              </div>

              <div className="min-w-50px text-center sticky-top me-20">
                {" "}
                {intl.formatMessage({ id: "POINT" })}
              </div>
            </div>
            {/* end::Table head */}
            {/* begin::Table body */}
            {/* <tbody id="table-body-ranking"> */}
            {type === "OVERALL" ? <Overall /> : <Invitation />}
            {/* </tbody> */}
            {/* end::Table body */}
          </div>
          {/* {!isFetching && data && data.length <= 0 ? (
            <div className="card-body py-3 w-100 min-h-350px d-flex align-items-center">
              <EmptyChart />
            </div>
          ) : null} */}
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { TopUserTable };
