/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Tabs, TabsProps } from "antd";
import LanguageTabItem from "app/_components/LanguageTabItem";

dayjs.extend(customParseFormat);

const Step1 = ({ data, updateData, hasError, isLoading }: any) => {
  const intl = useIntl();

  const defaultForm = {
    title: [] as any,
    body: [] as any,
    device_type: "all",
    type: 0,
    redirect_type: 16,
  };

  const onChangeTitleAndBody = (value: any, type: string, lang: string) => {
    if (type === "title") {
      const existTitle =
        formData.title &&
        formData.title.find((item: any) => item.lang === lang);
      if (existTitle) {
        const newFormData = {
          ...formData,
        };
        newFormData.title &&
          newFormData.title.map((item: any) => {
            if (item.lang === lang) {
              item.text = value;
            }
          });
        setFormData(newFormData);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          title: [...formData.title, { lang, text: value }],
        }));
      }
    }

    if (type === "body") {
      const existBody =
        formData.body && formData.body.find((item: any) => item.lang === lang);
      if (existBody) {
        const newFormData = {
          ...formData,
        };
        newFormData.body &&
          newFormData.body.map((item: any) => {
            if (item.lang === lang) {
              item.text = value;
            }
          });
        setFormData(newFormData);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          body: [...formData.body, { lang, text: value }],
        }));
      }
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <Typography sx={{ fontWeight: 500, fontSize: "1.2rem" }}>
          {intl.formatMessage({ id: "ENGLISH" })}
        </Typography>
      ),
      children: (
        <LanguageTabItem
          lang={"en"}
          title={"TITLE"}
          titlePlaceholder="PUSH-NOTICE.TITLE.PLACEHOLDER"
          data={data}
          onChangeData={onChangeTitleAndBody}
          hasError={hasError}
          content="CONTENT"
          contentPlaceholder="TYPE-CONTENT"
        />
      ),
    },
    {
      key: "2",
      label: (
        <Typography sx={{ fontWeight: 500, fontSize: "1.2rem" }}>
          {intl.formatMessage({ id: "KOREAN" })}
        </Typography>
      ),
      children: (
        <LanguageTabItem
          lang={"ko"}
          title={"TITLE"}
          titlePlaceholder="PUSH-NOTICE.TITLE.PLACEHOLDER"
          data={data}
          onChangeData={onChangeTitleAndBody}
          hasError={hasError}
          content="CONTENT"
          contentPlaceholder="TYPE-CONTENT"
        />
      ),
    },
  ];

  const [formData, setFormData] = useState(defaultForm);

  const onChangeData = (value: any, type: any) => {
    const newValue = value.target.value;

    setFormData((prevData) => ({
      ...prevData,
      [type]: newValue.toString(),
    }));
  };

  useEffect(() => {
    updateData(formData);
  }, [formData]);
  return (
    <>
      <div
        className="d-flex flex-column current gap-4 p-2"
        data-kt-stepper-element="content"
      >
        <div className="row">
          <div className="fv-row mb-3">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span className="required">
                {intl.formatMessage({ id: "DEVICES" })}
              </span>
            </label>
            <FormControl
              fullWidth
              variant="outlined"
              sx={{
                backgroundColor: "#f9f9f9",
                border: "none",
                borderRadius: "0.625rem",
                fieldset: {
                  border: "none",
                },
                ".MuiInputBase-root": {
                  backgroundColor: "#f9f9f9",
                  border: "none",
                },
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.device_type}
                onChange={(date) => onChangeData(date, "device_type")}
              >
                <MenuItem value={"all"}>
                  {intl.formatMessage({ id: "ALL" })}
                </MenuItem>
                <MenuItem value={"ios"}>
                  {intl.formatMessage({ id: "IOS" })}
                </MenuItem>
                <MenuItem value={"android"}>
                  {intl.formatMessage({ id: "ANDROID" })}
                </MenuItem>
              </Select>
            </FormControl>

            {hasError?.name && (
              <div className="fv-plugins-message-container">
                <div
                  data-field="appname"
                  data-validator="notEmpty"
                  className="fv-help-block"
                >
                  {hasError?.name}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-12">
          <Tabs defaultActiveKey="1" items={items} />
        </div>

        {/* <div onClick={handleShowSettingModal}>
            <KTIcon
              iconName="setting-2"
              iconType="solid"
              className="fs-4 cursor-pointer me-1 position-absolute mt-n4 mt-lg-n10 end-0 end-lg-0"
            />
          </div> */}
      </div>
    </>
  );
};

export { Step1 };
