export const PERMISSIONS = {
  USER: {
    VIEW_LIST: "list-admin",
    VIEW_DETAIL: "view-admin",
  },

  QA: {
    ANSWER: "answer-question",
  },

  JOURNEY: {
    VIEW_LIST: "list-journey",
  },

  SHOP: {
    VIEW_LIST: "list-location",
    CREATE_IMPORT: "create-import-location",
    UPDATE: "update-location",
    DELETE: "delete-location",
  },

  COUPON: {
    VIEW_LIST: "list-coupon",
    CREATE_IMPORT: "create-import-coupon",
    UPDATE: "update-coupon",
    DELETE: "delete-coupon",
  },

  CAR: {
    VIEW_LIST: "list-journey",
  },

  NOTIFICATION: {
    VIEW_LIST: "list-notification",
    CREATE: "create-notification",
  },

  POLICY: {
    VIEW_LIST: "list-policy",
    CREATE: "create-policy",
    UPDATE: "update-policy",
    DELETE: "delete-policy",
  },

  BANNER: {
    VIEW_LIST: "list-banner",
    CREATE: "create-banner",
    UPDATE: "update-banner",
    DELETE: "delete-banner",
  },

  ADMIN: {
    VIEW_LIST: "list-admin",
    CREATE: "create-admin",
    UPDATE: "update-admin",
    DELETE: "delete-admin",
  },

  ROLE: {
    VIEW_LIST: "list-role",
    CREATE: "create-role",
    UPDATE: "update-role",
    DELETE: "delete-role",
  },
};

export const PLATFORM = [
  {
    value: "google",
    label: "PLATFORM-GOOGLE",
  },

  {
    value: "apple",
    label: "PLATFORM-APPLE",
  },

  {
    value: "amazon",
    label: "PLATFORM-AMAZON",
  },

  {
    value: "facebook",
    label: "PLATFORM-FACEBOOK",
  },

  {
    value: "naver",
    label: "PLATFORM-NAVER",
  },

  {
    value: "kakao",
    label: "PLATFORM-KAKAO",
  },

  {
    value: "wishnote",
    label: "PLATFORM-WISHNOTE",
  },
];

export const POST_TYPE = [
  {
    value: 1,
    label: "GOAL",
  },
  {
    value: 2,
    label: "WISHBOARD",
  },
  {
    value: 3,
    label: "ADS",
  },
  {
    value: 4,
    label: "GOOD_CONTENT",
  },
  {
    value: 5,
    label: "DAILY",
  },
  {
    value: 6,
    label: "PREMIUM",
  },
];

export const POST_SHOW_STATUS = [
  {
    value: "SHOW",
    label: "SHOW",
  },

  {
    value: "DELETED",
    label: "DELETED",
  },
];

export const NOTIFICATION_STATUS = [
  {
    value: "waiting",
    label: "NOTIFY.STATUS.WAITING",
  },

  {
    value: "completed",
    label: "NOTIFY.STATUS.COMPLETED",
  },
];

export const NOTIFICATION_DEVICE = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "ios",
    label: "IOS",
  },
  {
    value: "android",
    label: "ANDROID",
  },
];

export const CS_REPLY = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "1",
    label: "YES",
  },
  {
    value: "0",
    label: "NO",
  },
];

export const ACCOUNT_TYPE = [
  { value: "", label: "ALL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "0", label: "NORMAL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "1", label: "MASTER", color: "gold" },
  { value: "2", label: "INFLUENCER", color: "lime" },
];

export const ACCOUNT_TYPE_UPDATE = [
  { value: "0", label: "NORMAL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "1", label: "MASTER", color: "gold" },
  { value: "2", label: "INFLUENCER", color: "lime" },
];

export const NOTIFY_TYPE = [
  { value: "", label: "ALL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "1", label: "MANUAL", color: "rgba(0, 0, 0, 0.25)" },
  { value: "2", label: "AUTO", color: "gold" },
];

export const OS_OPTIONS = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "ios",
    label: "IOS",
  },
  {
    value: "android",
    label: "ANDROID",
  },
];

export const RANKING_SETTING_STATUS_OPTIONS = [
  {
    value: "all",
    label: "ALL",
  },

  {
    value: "private",
    label: "PRIVATE",
  },
  {
    value: "public",
    label: "PUBLIC",
  },
];

export const RANKING_SETTING_EDIT_STATUS_OPTIONS = [
  {
    value: "private",
    label: "PRIVATE",
  },
  {
    value: "public",
    label: "PUBLIC",
  },
];

export const EXTRA_TYPE = [
  {
    value: "charge",
    label: "CHARGE",
  },
  {
    value: "revenue",
    label: "REVENUE",
  },
  {
    value: "gift",
    label: "GIFT",
  },
  {
    value: "bonus",
    label: "BONUS",
  },
  {
    value: "refund",
    label: "REFUND",
  },
  {
    value: "withdrawal",
    label: "WITHDRAWAL",
  },
  {
    value: "buy",
    label: "BUY",
  },
  {
    value: "donate",
    label: "DONATE",
  },
];

//clone images/video
export const FILE_TYPE = [
  { value: "", label: "ALL" },
  { value: "image", label: "IMAGE" },
  { value: "video", label: "VIDEO" },
];

export const CLONE_RESOURCE = [
  { value: "", label: "ALL" },
  { value: "pexels.com", label: "PEXELS" },
  { value: "pixabay.com", label: "PIXABAY" },
  { value: "unsplash.com", label: "UNSPLASH" },
];

export const CLONE_MEDIA_CATEGORY = [
  { value: "", label: "ALL" },
  { value: "car", label: "CAR" },
  { value: "building", label: "BUILDING" },
  { value: "yacht", label: "YACHT" },
];
