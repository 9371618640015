import { KTCard, OPTION_KEYS_HEADER } from "_metronic/helpers";
import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { Table } from "app/_components/table/Table";
import { useEffect, useMemo } from "react";
import { getNotificationLogsType, getNotificationLogs } from "./core/_requests";
import TableSummary from "app/_components/coreListData/header/TableSummary";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { Column } from "react-table";
import UserCell from "app/_components/table/cells/UserCell";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { NotificationLog } from "./core/_models";
import { PageTitle } from "_metronic/layout/core";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";

const NotificationLogs = () => {
  const notificationLogs = useQueryResponseData();
  const data = useMemo(() => notificationLogs || [], [notificationLogs]);
  const res = useQueryResponsePagination();
  const intl = useIntl();

  const { pageInstance } = useQueryRequest();
  const cardList = [
    {
      title: intl.formatMessage({
        id: "TOTAL-LOG",
      }),
      value: res?.total,
    },
  ];

  const {
    isFetching: isFetchingType,
    refetch: refetchType,
    data: responseType,
  } = useQuery(
    "all_types",
    async () => {
      const res = await getNotificationLogsType();
      return res;
    },
    { cacheTime: 20000, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  function formatJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const notificationColumns: ReadonlyArray<Column<NotificationLog>> = [
    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="ID"
          className="min-w-50px text-start"
          useQueryRequest={useQueryRequest}
        />
      ),
      id: "id",
      accessor: "id",
    },
    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="USER"
          className="min-w-50px text-start"
          useQueryRequest={useQueryRequest}
        />
      ),
      id: "user",
      accessor: "user",
      Cell: ({ ...props }) => {
        const row = props.row.original;
        return <UserCell {...row.user} />;
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="TITLE"
          className="min-w-50px text-start"
          useQueryRequest={useQueryRequest}
        />
      ),
      id: "title",
      accessor: "title",
      Cell: ({ ...props }) => {
        const row = props.row.original;
        const format = formatJsonString(row?.title)
          ? JSON.parse(row?.title).find((r: any) => r?.lang === intl.locale)
              ?.text || "-"
          : row?.title;
        return <div>{format}</div>;
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="BODY"
          className="min-w-50px text-start"
          useQueryRequest={useQueryRequest}
        />
      ),
      id: "body",
      accessor: "body",
      Cell: ({ ...props }) => {
        const row = props.row.original;
        const format = formatJsonString(row?.body)
          ? JSON.parse(row?.body).find((r: any) => r?.lang === intl.locale)
              ?.text || "-"
          : row?.body;
        return <div>{format}</div>;
      },
    },

    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="TYPE"
          className="min-w-50px text-start"
          useQueryRequest={useQueryRequest}
        />
      ),
      id: "type",
      accessor: "type",
      Cell: ({ ...props }) => {
        const row = props.row.original;
        return (
          <div>
            {responseType.data.find((d: any) => d.id === row.type)?.screen}
          </div>
        );
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader
          tableProps={props}
          title="CREATED-AT"
          className="d-flex min-w-100px"
          useQueryRequest={useQueryRequest}
          isNoSort={false}
          isCenter={true}
          isForceDesc={true}
        />
      ),
      id: "created_at",
      accessor: "created_at",
      Cell: ({ ...props }) => {
        const row = props.row.original;
        return <DateTimeCell date={row.created_at} />;
      },
    },
  ];

  const optionsFilterHeader: any = OPTION_KEYS_HEADER.NOTIFICATION_LOG;

  useEffect(() => {
    if (
      responseType &&
      responseType.data.length &&
      !optionsFilterHeader.some((option: any) => option.type === "type")
    ) {
      const TYPE_OPTIONS = responseType.data.map((i: any) => {
        return {
          value: i.id,
          label: intl.formatMessage({
            id: i.screen.toUpperCase(),
          }),
        };
      });
      optionsFilterHeader.push({
        id: "MULTI-OPTIONS",
        title: "ACTION-TYPE",
        type: "type",
        dataOptions: TYPE_OPTIONS,
      });
    }
  }, [responseType]);

  useEffect(() => {
    refetchType();
  }, [refetchType]);

  return (
    <div key={pageInstance}>
      <PageTitle>
        {intl.formatMessage({ id: "MENU.NOTIFICATION-LOG" })}
      </PageTitle>
      <KTCard>
        {responseType && responseType.data && (
          <UsersListHeader optionsHeader={optionsFilterHeader} />
        )}

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
          <TableSummary cardList={cardList} />
        </div>
        <Table
          defineColumns={notificationColumns}
          data={data}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
        />
      </KTCard>
    </div>
  );
};

const NotificationLogWrapper = () => (
  <TableWrapper getPanigation={getNotificationLogs}>
    <NotificationLogs />
  </TableWrapper>
);

export default NotificationLogWrapper;
