import axios, { AxiosResponse } from "axios";
import { DataListCsResponse, ReplyCSParams, ReplyCSResponse } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
const GET_LIST_CS_URL = `${API_URL}/review`;
const REPLY_CS_URL = `${API_URL}/review/reply-google-play`;
const REPLY_CS_URL_APPLE = `${API_URL}/review/reply-apple-store`;
const getListCS = (params: any): Promise<DataListCsResponse> => {
  return (
    axios
      .get(`${GET_LIST_CS_URL}`, { params: params })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        return d.data.data;
      })
  );
};

const submitReplyCSApple = (
  params: ReplyCSParams
): Promise<ReplyCSResponse> => {
  return axios
    .post(`${REPLY_CS_URL_APPLE}`, params)
    .then((d: AxiosResponse<any>) => {
      return d.data;
    });
};

const submitReplyCS = (
  params: ReplyCSParams,
  type: string
): Promise<ReplyCSResponse> => {
  if (type === "apple_store") {
    return submitReplyCSApple(params);
  }

  return (
    axios
      .post(`${REPLY_CS_URL}`, params)
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        return d.data;
      })
  );
};

export { getListCS, submitReplyCS };
