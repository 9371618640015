/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLang } from "_metronic/i18n/Metronici18n";
import ApexCharts, { ApexOptions } from "apexcharts";
import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import {
  useQueryAppDownloadsReportResponseData,
  useQueryResponse,
} from "./core/QueryResponseProvider";
import NumberDelay from "app/_components/NumberDelay";

type Props = {
  className: string;
  isPageMode?: boolean;
};

const AppDownloadChart: React.FC<Props> = ({
  className,
  isPageMode = false,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const intl = useIntl();

  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(!isPageMode ? 200 : 400, data, x, lang, intl)
    );

    if (chart) {
      chart.render();
    }

    return chart;
  };

  let data: any = useQueryAppDownloadsReportResponseData();

  const { isLoadingAppDownloadsChart } = useQueryResponse();

  // const lineChart1 = {
  //   name: intl.formatMessage({ id: "OVERVIEW.USER_NEW" }),
  //   data: data.map((obj) => parseInt(obj.today_user)),
  // };

  // const lineChart2 = {
  //   name: intl.formatMessage({ id: "OVERVIEW.USER_TOTAL" }),
  //   data: data.map((obj) => parseInt(obj.total_user)),
  // };

  // const formattedData = [lineChart2, lineChart1];

  const x = data.map((item: any) => item.download_date);

  const lang = useLang();

  useEffect(() => {
    let chart: any = null;
    if (data.length > 0) {
      chart = refreshMode();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, data]);

  const lastItem = data[data?.length - 1];

  return (
    <div className={`card ${className}`} style={{ backgroundColor: "#f8fdff" }}>
      {/* begin::Beader */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {intl.formatMessage({ id: "OVERVIEW.APP.DOWNLOAD.TITLE" })}
          </span>

          <span className="text-muted fw-semibold fs-7">
            {intl.formatMessage({
              id: "OVERVIEW.APP.DOWNLOAD.DESCRIPTION",
            })}
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body p-0 d-flex flex-column">
        {/* begin::Stats */}
        <div
          className="card-p pt-5  flex-grow-1"
          style={{ backgroundColor: "rgb(238 247 255)", borderRadius: 10 }}
        >
          <div className="d-flex justify-content-around">
            <div>
              <div className=" text-muted fw-semibold">
                {intl.formatMessage({
                  id: "OVERVIEW.MEMBERS.ANDROID",
                })}{" "}
              </div>
              {/* end::Label */}
              {/* begin::Stat */}
              <div>
                <div
                  className="fs-4 fw-bold"
                  style={{ color: "rgb(142, 202, 230)" }}
                >
                  <NumberDelay number={lastItem?.android_total} />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className=" text-muted fw-semibold">
                  {intl.formatMessage({
                    id: "OVERVIEW.MEMBERS.IOS",
                  })}
                </div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div
                    className="fs-4 fw-bold"
                    style={{ color: "rgb(7, 172, 255)" }}
                  >
                    <NumberDelay number={lastItem?.ios_total} />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div className=" text-muted fw-semibold">
                  {intl.formatMessage({
                    id: "OVERVIEW.APP.DOWNLOAD.TOOLTIP",
                  })}{" "}
                </div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div
                    className="fs-4 fw-bold"
                    style={{ color: "rgb(33, 158, 188)" }}
                  >
                    <NumberDelay number={lastItem?.download_total} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div className="w-100 h-200px">
          {!isLoadingAppDownloadsChart && data.length > 0 ? (
            <div>
              {/* begin::Chart */}
              <div
                ref={chartRef}
                className={`mixed-widget-3-chart card-rounded-bottom ${
                  isLoadingAppDownloadsChart ? "h-150px" : ""
                }`}
                // data-kt-chart-color={chartColor}
                // style={{ height: chartHeight }}
              ></div>
            </div>
          ) : null}
          {isLoadingAppDownloadsChart ? (
            <div className="position-absolute z-index-1 d-flex w-100 h-100 top-0 start-0 bg-light d-flex align-items-center justify-content-center bg-opacity-75">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null}
        </div>

        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );

  return (
    <>
      <div
        className={`card ${className}`}
        style={{ backgroundColor: "rgb(243 251 255)" }}
      >
        <div className="card-body d-flex flex-column p-0">
          {/* begin::Stats */}

          <div
            className={`pt-2 card-rounded h-150px w-100`}
            style={{ backgroundColor: "#076591", borderRadius: 10 }}
          >
            <div className="card-header border-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1 text-white">
                  {intl.formatMessage({ id: "OVERVIEW.APP.DOWNLOAD.TITLE" })}
                </span>

                <span className="text-muted fw-semibold fs-7">
                  {intl.formatMessage({
                    id: "OVERVIEW.APP.DOWNLOAD.DESCRIPTION",
                  })}
                </span>
              </h3>
            </div>
            <div className="d-flex justify-content-around">
              <div className="d-flex text-center flex-column text-white">
                <span className="fw-semibold fs-7">
                  {intl.formatMessage({
                    id: "OVERVIEW.MEMBERS.ANDROID",
                  })}
                </span>
                <span
                  className="fw-bold fs-1 pt-1"
                  style={{ color: "#219ebc" }}
                >
                  {lastItem && lastItem.android_total ? (
                    <NumberDelay number={lastItem.android_total} />
                  ) : isLoadingAppDownloadsChart ? (
                    <div className="placeholder-glow" role="status">
                      <span className="placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px"></span>
                    </div>
                  ) : (
                    0
                  )}
                </span>
              </div>
              <div className="d-flex text-center flex-column text-white">
                <span className="fw-semibold fs-7">
                  {intl.formatMessage({
                    id: "OVERVIEW.MEMBERS.IOS",
                  })}
                </span>
                <span
                  className="fw-bold fs-1 pt-1"
                  style={{ color: "rgb(7 172 255)" }}
                >
                  {(lastItem && lastItem.ios_total) ||
                  (lastItem && lastItem.ios_total === 0) ? (
                    <NumberDelay number={lastItem.ios_total} />
                  ) : isLoadingAppDownloadsChart ? (
                    <div className="placeholder-glow" role="status">
                      <span className="placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px"></span>
                    </div>
                  ) : (
                    0
                  )}
                </span>
              </div>
              <div className="d-flex text-center flex-column text-white">
                <span className="fw-semibold fs-7">
                  {intl.formatMessage({
                    id: "OVERVIEW.APP.DOWNLOAD.TOOLTIP",
                  })}
                </span>
                <span
                  className="fw-bold fs-1 pt-1"
                  style={{ color: "#8ecae6" }}
                >
                  {lastItem && lastItem.download_total ? (
                    <NumberDelay number={lastItem.download_total} />
                  ) : isLoadingAppDownloadsChart ? (
                    <div className="placeholder-glow" role="status">
                      <span className="placeholder placeholder-loading bg-#feb019 w-15px h-3px min-h-3px"></span>
                    </div>
                  ) : (
                    0
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="position-relative h-250px pt-8">
            {isLoadingAppDownloadsChart ? (
              <div className="position-absolute z-index-1 d-flex w-100 h-100 top-0 start-0 bg-light d-flex align-items-center justify-content-center bg-opacity-75">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : null}
            {/* {data && data.length > 0 ? ( */}

            {!isLoadingAppDownloadsChart && data.length > 0 ? (
              <div
                ref={chartRef}
                id="kt_charts_widget_3_chart"
                className={`w-100 ${
                  isLoadingAppDownloadsChart ? "h-250px" : ""
                } `}
              ></div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const AppDownloadChartWrapper = ({ className, isPageMode }: any) => (
  <AppDownloadChart className={className} isPageMode={isPageMode} />
);

export default AppDownloadChartWrapper;

function getChartOptions(
  height: number,
  formattedData: any,
  x: any,
  lang: any,
  intl: any
): ApexOptions {
  const borderColor = getCSSVariableValue("--bs-gray-200");
  const labelColor = getCSSVariableValue("--bs-gray-500");
  return {
    series: [
      {
        type: "area",
        data: formattedData.map((item: any) => item.android_total),
        name: intl.formatMessage({
          id: "OVERVIEW.MEMBERS.ANDROID",
        }),
      },
      {
        type: "area",
        data: formattedData.map((item: any) => item.ios_total),
        name: intl.formatMessage({
          id: "OVERVIEW.MEMBERS.IOS",
        }),
      },
      {
        type: "bar",
        data: formattedData.map((item: any) => item.download_total),
        name: intl.formatMessage({
          id: "OVERVIEW.APP.DOWNLOAD.TOOLTIP",
        }),
      },
    ],

    chart: {
      fontFamily: "inherit",
      type: "line",
      height: height,
      toolbar: {
        show: false,
        tools: {
          download: true,
          zoom: false,
          reset: false,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },

      // background: "linear-gradient(195deg, #607D8B, #455A64)",
    },
    fill: {
      //type: ["solid", "gradient", "gradient"],
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 1,
        stops: [100, 100, 20, 100],
      },
    },
    grid: {
      show: true,
      borderColor: borderColor,
      strokeDashArray: 6,
    },
    responsive: [
      // {
      //   breakpoint: 1280,
      //   options: {
      //     chart: {
      //       height: 200,
      //     },
      //   },
      // },
    ],

    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
      // colors: [color2, color1],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth:
          x && x.length > 29
            ? "80%"
            : x && x.length > 14
            ? "40%"
            : x && x.length > 7
            ? "20%"
            : x && x.length > 3
            ? "16%"
            : "5%",
      },
    },
    xaxis: {
      categories: x,
      tickAmount: 3,
      axisTicks: {
        show: true,
      },

      crosshairs: {
        position: "front",
        stroke: {
          color: labelColor,
          width: 1,
          dashArray: 3,
        },
      },

      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },

        formatter(value) {
          return new Date(value).toLocaleDateString(
            lang === "en" ? "en-US" : "ko-KR",
            {
              month: "short",
              day: "2-digit",
            }
          );
        },
        rotate: 0,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 3,

      forceNiceScale: true,
      labels: {
        style: {
          colors: "gray",
          fontSize: "12px",
        },
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
      min: 0,
      crosshairs: {
        show: false,
      },
      decimalsInFloat: 0,
    },

    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return val + "";
        },
      },
      marker: {
        show: true,
        // fillColors: [secondaryColor, color1],
      },
    },

    colors: ["rgb(142, 202, 230)", "rgb(7, 172, 255)", "rgb(33, 158, 188)"],

    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [0],
    },

    markers: {
      //colors: ["red", "blue", "green"],
      // strokeColors: [secondaryColor, secondaryLightColor, baseLightColor],
      strokeWidth: 3,
    },
  };
}
