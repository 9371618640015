// @ts-nocheck
import { CircularProgress } from "@mui/material";
import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import { Popconfirm, Select, Tag, Tooltip, notification } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import UserCellPoint from "app/_components/point/UserCellPoint";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import { ACCOUNT_TYPE_UPDATE } from "app/constants";
import clsx from "clsx";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { delete_admin, edit_user } from "../core/_requests";
import UserInfoCell from "app/_components/table/columns/UserInfoCell";
import { LevelRespon } from "../core/_models";

const usersColumns: any = (
  hasPermissionDelete: boolean,
  dataLevel?: Array<LevelRespon>,
  handleChangeLevel?: (e) => void
) => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PLATFORM"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "platform",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <PlatformCell className="min-w-150px" user={row} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="OS"
        useQueryRequest={useQueryRequest}
        className="min-w-150px"
      />
    ),
    id: "device_os",
    accessor: "device_os",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <CellOS row={row} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="USER"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "user",
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      return <UserInfoCell user={row} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACCOUNT-TYPE"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),

    id: "type",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const intl = useIntl();
      const { refetch } = useQueryResponse();
      const [open, setOpen] = useState(false);

      const row = props?.row?.original;
      return (
        <div className="mini-select">
          <Select
            options={ACCOUNT_TYPE_UPDATE.map((i) => {
              return {
                ...i,
                label: intl.formatMessage({ id: i.label }),
              };
            })}
            value={[row.type.toString()]}
            onChange={async (value) => {
              const body = {
                type: Number(value.pop()),
              };
              const res = await edit_user(row.id, body);
              if (res?.data?.code == 200) refetch();
              setOpen(false);
            }}
            tagRender={(props) => {
              const item = ACCOUNT_TYPE_UPDATE.find(
                (i) => i.value == props.value
              );
              return (
                <Tag color={item?.color} style={{ marginRight: 3 }}>
                  {props.label}
                </Tag>
              );
            }}
            mode="multiple"
            open={open}
            onDropdownVisibleChange={(visible) => setOpen(visible)}
            // suffixIcon={null}
          />
        </div>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="LEVEL"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isNoSort={true}
        isCenter={true}
      />
    ),
    id: "level",
    accessor: "level",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const { original } = props?.row;
      return (
        <div className="mini-select">
          {dataLevel && dataLevel?.length ? (
            <Select
              options={dataLevel.map((item) => {
                return {
                  value: item.level,
                  label: (
                    <div className="w-100" style={{ display: "inline-flex"}}>
                      <div
                        className="w-100 d-flex align-items-center justify-content-between"
                        style={{ gap: "15px" }}
                      >
                        <div>
                          <span className="fs-6 fw-bold text-dark">
                            Lv.{item.level} {item.name}
                          </span>
                        </div>
                        <div
                          class="d-flex align-items-center justify-content-center"
                          style={{ fontSize: "12px" }}
                        >
                          <img
                            src="/media/svg/points/point-wp.svg"
                            alt=""
                            class="h-20px"
                          />
                          <span
                            style={{
                              marginLeft: "4px",
                              color: "rgb(241, 65, 108)",
                            }}
                          >
                            P{" "}
                            {item.maxScore
                              ? [item.minScore, item.maxScore].join(" - ")
                              : item.minScore}
                          </span>
                        </div>
                      </div>
                    </div>
                  ),
                };
              })}
              value={original?.level || 1}
              onChange={(level: number) => {
                handleChangeLevel &&
                  handleChangeLevel({ id: original?.id, level });
              }}
              popupMatchSelectWidth={280}
            />
          ) : null}
        </div>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="POINT"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "score",
    accessor: "score",
    isCenterRow: true,
    Cell: ({ ...props }) => <UserCellPoint row={props?.row?.original} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="RANK"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "rank",
    accessor: "rank",
    isCenterRow: true,
  },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title="ACTIVE.USERS-SHARE.COUNT"
  //       className="min-w-150px"
  //       useQueryRequest={useQueryRequest}
  //       isCenter={true}
  //     />
  //   ),
  //   id: "share",
  //   accessor: "share",
  //   isCenterRow: true,
  // },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="REGISTER-DATE"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
        isForceDesc={true}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "created_at",
    accessor: "created_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.created_at;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="UPDATED-AT"
        className="min-w-150px"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "updated_at",
    accessor: "updated_at",
    Cell: ({ ...props }) => {
      const date = props?.row?.original.created_at;
      return <DateTimeCell date={date} />;
    },
  },

  /* {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="STATUS"
        useQueryRequest={useQueryRequest}
      />
    ),
    id: "action-detail",
    Cell: ({ ...props }) => <EditUserStatus row={props?.row?.original} />,
  }, */

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "action",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const row = props?.row?.original;
      const location = useLocation();
      return (
        <div className="d-flex" style={{ gap: 5 }}>
          {hasPermissionDelete && (
            <DeleteComponent row={props?.row?.original} />
          )}

          <Tooltip placement="top" title="User detail">
            <Link
              to={`${location.pathname}/detail/${props.row.original.id}`}
              className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
            >
              <KTIcon iconName="arrow-right" className="fs-2" />
            </Link>
          </Tooltip>
        </div>
      );
    },
  },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title="DELETE"
  //       className="min-w-150px"
  //       useQueryRequest={useQueryRequest}
  //     />
  //   ),
  //   id: "action-delete",
  //   Cell: ({ ...props }) => {
  //     const row = props?.row?.original;
  //     return <DeleteComponent row={row} />;
  //   },
  // },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader
  //       tableProps={props}
  //       title="DETAIL"
  //       // className="text-end min-w-100px"
  //       useQueryRequest={useQueryRequest}
  //       isCenter={true}
  //     />
  //   ),
  //   id: "actions",
  //   isCenterRow: true,
  //   Cell: ({ ...props }) => (
  //     <ActionsCell
  //       id={props.data[props.row.index].id}
  //       useQueryResponse={useQueryResponse}
  //     />
  //   ),
  // },
];

function DeleteComponent({ row }) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();
  const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState(false);

  const handleDeleteUser = async () => {
    setIsLoadingDeleteUser(true);
    const res = await delete_admin(row.id);
    setIsLoadingDeleteUser(false);
    if (res.status == 200) {
      notification.success({
        message: intl.formatMessage({ id: "DELETE.USER-SUCCESS" }),
      });
      refetch();
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Popconfirm
        title={intl.formatMessage({ id: "DELETE" })}
        description={intl.formatMessage({ id: "USER.DELETE.CONFIRM" })}
        onConfirm={handleDeleteUser}
        onCancel={() => {}}
        okText={intl.formatMessage({ id: "YES" })}
        cancelText={intl.formatMessage({ id: "NO" })}
      >
        <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
          {isLoadingDeleteUser ? (
            <CircularProgress size={16} />
          ) : (
            <KTIcon iconName="trash" className="fs-2" />
          )}
        </button>
      </Popconfirm>
    </div>
  );
}

const PlatformCell = ({ user, className = "" }) => {
  let imageSrc;
  switch (user?.platform) {
    case "amazon":
      imageSrc = toAbsoluteUrl("/media/social_media/amazon.svg");
      break;

    case "apple":
    case "firebase_apple":
      imageSrc = toAbsoluteUrl("/media/social_media/apple.svg");
      break;

    case "facebook":
      imageSrc = toAbsoluteUrl("/media/social_media/facebook.svg");
      break;

    case "google":
      imageSrc = toAbsoluteUrl("/media/social_media/google.svg");
      break;

    case "kakao":
      imageSrc = toAbsoluteUrl("/media/social_media/kakao.svg");
      break;

    case "naver":
      imageSrc = toAbsoluteUrl("/media/social_media/naver.svg");
      break;

    default:
      imageSrc = toAbsoluteUrl("/media/social_media/wishnote.png");
  }

  return (
    <div className={`${className} d-flex align-items-center`}>
      {/* begin:: Avatar */}
      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
        <div className={clsx("symbol-label fs-3")}>
          <img src={imageSrc} className="w-50 rounded-circle" />
        </div>
      </div>
    </div>
  );
};

const CellOS = ({ row }) => {
  const intl = useIntl();
  const data = row.device_os && row.device_os.split(",");
  const convertData =
    data &&
    data.length > 1 &&
    data.map((item) => intl.formatMessage({ id: `${item?.toUpperCase()}` }));

  return (
    <div>
      {row.device_os
        ? convertData.length > 0
          ? convertData.toString()
          : intl.formatMessage({ id: `${row.device_os?.toUpperCase()}` })
        : ""}
    </div>
  );
};

export { usersColumns };
