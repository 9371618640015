/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLang } from "_metronic/i18n/Metronici18n";
import ApexCharts, { ApexOptions } from "apexcharts";
import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import {
  useQueryResponse,
  useQueryVideoViewsReportResponseData,
} from "./core/QueryResponseProvider";
import NumberDelay from "app/_components/NumberDelay";

type Props = {
  className: string;
  isPageMode?: boolean;
  isSummary?: boolean;
};

const VideoViewsReportChart: React.FC<Props> = ({
  className,
  isPageMode = false,
  isSummary = false,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const intl = useIntl();

  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(200, convertedData, lang, intl, x, convertedData1)
    );

    if (chart) {
      chart.render();
    }

    return chart;
  };

  const data = useQueryVideoViewsReportResponseData();

  const { isLoadingViewsChart } = useQueryResponse();

  const convertedData = data.map((item) => ({
    x: item.date,
    y: item.view_total,
  }));

  const convertedData1 = data.map((item) => item.view_total);

  const x = data.map((item) => item.date);

  const lang = useLang();

  useEffect(() => {
    let chart: any = null;
    if (data.length > 0) {
      chart = refreshMode();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, data]);

  const lastItem = data[data?.length - 1];

  return (
    <div className={`card ${className}`} style={{ backgroundColor: "#f8fdff" }}>
      <div className="card-body d-flex flex-column p-0">
        {/* begin::Stats */}
        <div className="flex-grow-1 card-p pb-0">
          <div className="d-flex flex-stack flex-wrap">
            <div className="me-2">
              <a href="#" className="text-dark text-hover-primary fw-bold fs-3">
                {intl.formatMessage({ id: "OVERVIEW.VIDEO.VIEWS.TITLE" })}
              </a>

              <div className="text-muted fs-7 fw-semibold">
                {intl.formatMessage({ id: "OVERVIEW.VIDEO.VIEWS.DESCRIPTION" })}
              </div>
            </div>

            <div className={`fw-bold fs-3`} style={{ color: "rgb(0,67,157)" }}>
              <NumberDelay number={lastItem?.view_total} />
            </div>
          </div>
        </div>
        {/* end::Stats */}

        {/* begin::Chart */}
        <div className="w-100 h-200px">
          {" "}
          <div
            ref={chartRef}
            className="mixed-widget-7-chart card-rounded-bottom"
          >
            {data.length > 0 ? (
              <div
                id="kt_charts_widget_3_chart"
                className="d-flex justify-content-center align-items-center"
              >
                <div className="realtime-line-chart w-100">
                  <div ref={chartRef} id="kt_charts_widget_3_chart"></div>
                </div>
              </div>
            ) : null}
          </div>
          {isLoadingViewsChart ? (
            <div className="position-absolute z-index-1 d-flex w-100 h-100 top-0 start-0 bg-light d-flex align-items-center justify-content-center bg-opacity-75">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null}
        </div>

        {/* end::Chart */}
      </div>
    </div>
  );
};

const VideoViewsReportChartWrapper = ({
  className,
  isPageMode,
  isSummary,
}: any) => (
  <VideoViewsReportChart
    className={className}
    isPageMode={isPageMode}
    isSummary={isSummary}
  />
);

export default VideoViewsReportChartWrapper;

function getChartOptions(
  height: number,
  formattedData: any,
  lang: any,
  intl: any,
  x: any,
  y: any
): ApexOptions {
  const lightColor = getCSSVariableValue("--bs-info-light");

  const lightColor1 = getCSSVariableValue("--bs-success-light");

  const color2 = "#fff5f8";
  const color1 = getCSSVariableValue("--bs-warning");
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");

  return {
    series: [
      {
        type: "area",
        data: y,
        name: intl.formatMessage({ id: "OVERVIEW.VIDEO.VIEWS.TITLE" }),
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "line",
      height: height,
      toolbar: {
        show: false,
        tools: {
          download: true,
          zoom: false,
          reset: false,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
      // background: "linear-gradient(195deg, #00BCD4, #0097A7)",
    },
    grid: {
      show: true,
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.65,
        stops: [1000, 100, 100, 100],
      },
    },
    responsive: [
      {
        breakpoint: 1280,
        options: {
          chart: {
            height: 200,
          },
        },
      },
      // {
      //   breakpoint: 1200,
      //   options: {
      //     chart: {
      //       height: 150,
      //     },
      //   },
      // },
    ],

    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth:
          formattedData.length > 10
            ? "80%"
            : formattedData.length > 7
            ? "60%"
            : "28%",
      },
    },

    xaxis: {
      categories: x,

      tickAmount: 4,
      axisTicks: {
        show: true,
      },

      labels: {
        show: true,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },

        formatter(value) {
          return new Date(value).toLocaleDateString(
            lang === "en" ? "en-US" : "ko-KR",
            {
              month: "short",
              day: "2-digit",
            }
          );
        },
        rotate: 0,
      },

      tooltip: {
        enabled: false,
      },
    },

    yaxis: {
      forceNiceScale: true,
      tickAmount: 3,
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
      min: 0,
      decimalsInFloat: 0,
    },

    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return val + "";
        },
      },
      marker: {
        show: true,
        fillColors: [color2, color1],
      },
    },

    legend: {
      labels: {
        colors: "#fff",
      },
    },

    colors: ["rgb(0,67,157)"],
  };
}
