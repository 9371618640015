/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLang } from "_metronic/i18n/Metronici18n";
import ApexCharts, { ApexOptions } from "apexcharts";
import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import {
  useQueryMembersReportResponse,
  useQueryMembersReportResponseData,
} from "./core/QueryResponseProvider";
import NumberDelay from "app/_components/NumberDelay";

type Props = {
  className: string;
  isPageMode?: boolean;
};

const UserRegistrationChart: React.FC<Props> = ({
  className,
  isPageMode = false,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const intl = useIntl();

  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(!isPageMode ? 200 : 400, formatData, lang, intl)
    );

    if (chart) {
      chart.render();
    }

    return chart;
  };

  const data = useQueryMembersReportResponseData();
  const { isLoadingMembersChart } = useQueryMembersReportResponse();

  const dataCol1 = data.map((item) => ({
    x: item.register_date,
    y: item.today_user,
  }));
  const dataCol2 = data.map((item) => ({
    x: item.register_date,
    y: item.total_user,
  }));
  const formatData = [dataCol1, dataCol2];
  const lang = useLang();

  useEffect(() => {
    let chart: any = null;
    chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, data]);

  const lastItem = data[data?.length - 1];

  return (
    <div className={`card ${className}`} style={{ backgroundColor: "#f8fdff" }}>
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {intl.formatMessage({
              id: "OVERVIEW.USER_STATUS.TITLE",
            })}
          </span>

          <span className="text-muted fw-semibold fs-7">
            {intl.formatMessage({
              id: "OVERVIEW.USER_STATUS.DESCRIPTION",
            })}
          </span>
        </h3>
      </div>

      <div className="card-body d-flex flex-column position-relative p-0">
        <div
          className="card-p pt-5  flex-grow-1"
          style={{ backgroundColor: "rgb(238 247 255)", borderRadius: 10 }}
        >
          <div className="d-flex justify-content-around">
            <div>
              <div className=" text-muted fw-semibold">
                {intl.formatMessage({ id: "OVERVIEW.USER_NEW" })}
              </div>
              {/* end::Label */}
              {/* begin::Stat */}
              <div>
                <div
                  className="fs-4 fw-bold"
                  style={{ color: "rgb(0,67,157)" }}
                >
                  <NumberDelay
                    number={(lastItem && lastItem.today_user) || 0}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className=" text-muted fw-semibold">
                  {intl.formatMessage({ id: "OVERVIEW.USER_TOTAL" })}
                </div>
                {/* end::Label */}
                {/* begin::Stat */}
                <div>
                  <div
                    className="fs-4 fw-bold"
                    style={{ color: "rgb(141, 162, 194,1)" }}
                  >
                    <NumberDelay
                      number={(lastItem && lastItem.total_user) || 0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Row */}
        </div>

        <div className="w-100 h-200px">
          {!isLoadingMembersChart && data.length > 0 ? (
            <div>
              {/* begin::Chart */}
              <div
                ref={chartRef}
                className={`mixed-widget-3-chart card-rounded-bottom ${
                  isLoadingMembersChart ? "h-150px" : ""
                }`}
                // data-kt-chart-color={chartColor}
                // style={{ height: chartHeight }}
              ></div>
            </div>
          ) : null}
          {isLoadingMembersChart ? (
            <div
              className={`position-absolute z-index-1 d-flex w-100 ${
                isPageMode ? "h-250px" : "h-300px"
              }  top-0 start-0 bg-light d-flex align-items-center justify-content-center bg-opacity-75`}
            >
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const UserRegistrationChartWrapper = ({ className, isPageMode }: any) => (
  <UserRegistrationChart className={className} isPageMode={isPageMode} />
);

export default UserRegistrationChartWrapper;

function getChartOptions(
  height: number,
  formattedData: any,

  lang: any,
  intl: any
): ApexOptions {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");

  const baseColor = "rgb(0,67,157)";
  const baseLightColor = "blue";
  const secondaryColor = "red";

  return {
    //series: formattedData,
    series: [
      {
        type: "bar",
        data: formattedData[0],
        name: intl.formatMessage({ id: "OVERVIEW.USER_NEW" }),
      },
      // {
      //   type: "bar",
      //   data: formattedData[1],
      //   name: intl.formatMessage({ id: "OVERVIEW.USER_TOTAL" }),
      // },
    ],

    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth:
          formattedData[0] && formattedData[0].length > 29
            ? "80%"
            : formattedData[0] && formattedData[0].length > 14
            ? "40%"
            : formattedData[0] && formattedData[0].length > 7
            ? "20%"
            : formattedData[0] && formattedData[0].length > 3
            ? "10%"
            : "5%",
      },
    },

    chart: {
      fontFamily: "inherit",
      stacked: true,
      height: height,
      toolbar: {
        show: false,
        tools: {
          download: true,
          zoom: false,
          reset: false,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
      background: "fff",
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
      },
    },
    responsive: [
      {
        breakpoint: 1280,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],

    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      tickAmount: 4,
      axisTicks: {
        show: true,
      },

      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },

        formatter(value) {
          return new Date(value).toLocaleDateString(
            lang === "en" ? "en-US" : "ko-KR",
            {
              month: "short",
              day: "2-digit",
            }
          );
        },
        rotate: 0,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 5,
      forceNiceScale: true,
      labels: {
        style: {
          colors: "gray",
          fontSize: "12px",
        },
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
      min: 0,
    },

    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return val + "";
        },
      },
      marker: {
        show: true,
      },
    },
    colors: [baseColor, secondaryColor, baseLightColor],

    legend: {
      show: false,
    },
  };
}
