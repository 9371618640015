import { KTCard, OPTION_KEYS_HEADER } from "_metronic/helpers";
import TableSummary from "app/_components/TableSummary";
import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { Table } from "app/_components/table/Table";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { getPanigationUsersDeleted } from "./core/_requests";
import { usersColumns } from "./table/_columns";

const UsersList = () => {
  const users = useQueryResponseData();
  const data = useMemo(() => users, [users]);

  const intl = useIntl();

  const { pageInstance } = useQueryRequest();

  const pag = useQueryResponsePagination();

  const res: any = useQueryResponse();

  const user_platform = res?.response?.user_platform;

  let cardList = [
    {
      title: intl.formatMessage({
        id: "USER",
      }),
      value: pag?.total,
    },
  ];

  if (user_platform?.length) {
    cardList = [
      ...cardList,
      {
        title: intl.formatMessage({
          id: "PLATFORM-GOOGLE",
        }),
        value:
          user_platform?.find((i: any) => i.platform == "google")?.total || "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-FACEBOOK",
        }),
        value:
          user_platform?.find((i: any) => i.platform == "facebook")?.total ||
          "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-WISHNOTE",
        }),
        value:
          user_platform?.find((i: any) => i.platform == "wishnote")?.total ||
          "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-KAKAO",
        }),
        value:
          user_platform?.find((i: any) => i.platform == "kakao")?.total || "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-NAVER",
        }),
        value:
          user_platform?.find((i: any) => i.platform == "naver")?.total || "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-AMAZON",
        }),
        value:
          user_platform?.find((i: any) => i.platform == "amazon")?.total || "0",
      },

      {
        title: intl.formatMessage({
          id: "PLATFORM-APPLE",
        }),
        value:
          user_platform?.find((i: any) => i.platform == "apple")?.total || "0",
      },
    ];
  }

  return (
    <>
      {" "}
      <UsersListHeader optionsHeader={OPTION_KEYS_HEADER.USER_DELETED} />
      <div key={pageInstance}>
        <KTCard>
          <TableSummary cardList={cardList} />
          <Table
            defineColumns={usersColumns}
            data={data}
            useQueryResponsePagination={useQueryResponsePagination}
            useQueryResponseLoading={useQueryResponseLoading}
            useQueryRequest={useQueryRequest}
          />
        </KTCard>
      </div>
    </>
  );
};

const UsersListWrapper = () => (
  <TableWrapper getPanigation={getPanigationUsersDeleted}>
    <UsersList />
  </TableWrapper>
);

export default UsersListWrapper;
