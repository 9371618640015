import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { PageTitle } from "../../../_metronic/layout/core";
import { getUserById } from "./core/_requests";
import { ProfileHeader } from "./ProfileHeader";

const ProfilePage = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [users, setUsers] = useState<any>();

  const params = useParams();
  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    async function GetData() {
      const res = await getUserById(params?.id);
      setUsers(res);
    }

    GetData();
  }, []);

  return (
    <div className="row col-12 d-flex">
      <div className="">
        <PageTitle>
          <div
            id="kt_page_title"
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="symbol symbol-circle symbol-5px overflow-hidden me-3"
                onClick={handleBackClick}
              >
                <div className="symbol-label" style={{ width: 30, height: 30 }}>
                  <button className="btn btn-lg text-center">
                    <span>
                      <i className="fa fa-arrow-left fa-3x"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div className={clsx("menu-title fw-bold fs-5")}>
                {intl.formatMessage({
                  id: "DETAIL",
                })}
              </div>
            </div>
          </div>
        </PageTitle>
        <ProfileHeader user={users} />
      </div>
      <div className="mt-4">
        <Outlet />
      </div>

      {/* <Overview data={users} params={params} /> */}
    </div>
  );
};

export default ProfilePage;
