import { QUERIES } from "_metronic/helpers";
import { PageTitle } from "_metronic/layout/core";
import { FilterHeader } from "_metronic/layout/core/FilterHeaderData";
import ActiveUserChartWrapper from "app/_modules/dashboard/components/ActiveUserChart";
import FilterByDate from "app/_modules/dashboard/components/FilterByDate";
import UserRegistrationChartWrapper from "app/_modules/dashboard/components/UserRegistrationChart";
import { useQueryRequest } from "app/_modules/dashboard/components/core/QueryRequestProvider";
import clsx from "clsx";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { PlatformWidget } from "./PlatformWidget";
import { TopUserTable } from "./TopUserTable";
import { getUsersSummary } from "./core/_requests";

dayjs.locale("pl");

export default function UsersSummaryPage() {
  const intl = useIntl();
  const { updateState, state } = useQueryRequest();
  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    [`${QUERIES.USERS_SUMMARY}`],
    () => {
      return getUsersSummary();
    },
    { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false }
  );

  const userFlatForm = response && response.user_platform;

  return (
    <div className="row g-5 g-xl-10 mb-5 position-relative mb-xl-10 justify-content-start">
      <PageTitle>
        <div
          id="kt_page_title"
          data-kt-swapper="true"
          data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 5,
            }}
          >
            <div className={clsx("menu-title fw-bold fs-5")}>
              {intl.formatMessage({ id: "MENU.USER-MANAGEMENT.SUMMARY" })}
            </div>
            {/* <div className="header-datepicker">
                  <FilterByDate typeChart="content" />
                </div> */}
          </div>
        </div>
      </PageTitle>

      <FilterHeader>
        <div className="py-3">
          <FilterByDate
            state={state}
            updateState={updateState}
            typeChart="content"
          />
        </div>
      </FilterHeader>

      <div /* style={{ display: "flex" }} */>
        {/*  <div style={{ position: "absolute", flex: 1 }}>
        <Globe />
      </div> */}
        {/* begin::Row */}
        <div className="row g-5 g-xl-10">
          <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-6">
            <div className="row">
              <div className="col-md-6 mb-5">
                <UserRegistrationChartWrapper className="h-100" />
              </div>
              <div className="col-md-6  mb-5">
                <PlatformWidget data={userFlatForm} className="h-100" />
              </div>
            </div>
            <ActiveUserChartWrapper isSummaryPage className="" />
            {/* <div className="row mt-6">
                  <div className="col-sm-6 mb-5">
                    <CountryWidget className="h-100" />
                  </div>
                  <div className="col-sm-6 mb-5">
                    <PlatformWidget data={userFlatForm} className="h-100" />
                  </div>
                </div> */}
          </div>
          {/* begin::Col */}
          <div
            className="col-md-12 col-lg-12 col-xl-12 col-xxl-6"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <TopUserTable className="" />
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}
      </div>
    </div>
  );
}
