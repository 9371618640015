import { Response } from "_metronic/helpers";
import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const CREATE_EVENT_URL = `${API_URL}/event/create`;
const CREATE_PATH_URL = `${API_URL}/event/cloudflare-signed-url`;

const createEvent = (data: any): Promise<any | undefined> => {
  // const formData = new FormData();
  // formData.append(
  //   "image",
  //   data.image
  // );
  // formData.append("title", JSON.stringify(data?.title));
  // formData.append("start_date", data?.start_date);
  // formData.append("end_date", data?.end_date);
  // formData.append("body", data?.body);
  // formData.append("device_type", data?.device_type);
  return axios
    .post(CREATE_EVENT_URL, data)
    .then((response: AxiosResponse<Response<any>>) => response)
    .then((response: Response<any>) => response);
};

const createPathCloudFrame = (data: any): Promise<any | undefined> => {
  return axios
    .post(CREATE_PATH_URL, data)
    .then((response: AxiosResponse<Response<any>>) => response)
    .then((response: Response<any>) => response);
};

const putUpload = async (params: { url: string, file: File }): Promise<boolean> => {
  try {
    const { url , file } = params;
    const file_buffer = await file.arrayBuffer()
    
    await axios.put(url, file_buffer, {
      headers: { 'Content-Type': file.type },
      transformRequest: (data, headers) => {
        if (headers?.Authorization) {
          delete headers?.Authorization;
        }
        return data
      }
    })
    return true
  } catch (error) {
    return false
  }
}

export { createEvent, createPathCloudFrame, putUpload };
