import { toAbsoluteUrl } from "_metronic/helpers";
import clsx from "clsx";
import { Link } from "react-router-dom";

const UserInfoCell = ({ user }: any) => (
  <div className="d-flex align-items-center">
    {/* begin:: Avatar */}
    <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
      {user?.avatar ? (
        <div className="symbol-label">
          <div
            className={`align-self-end my-auto h-100 w-100`}
            style={{
              backgroundImage: `url(${user?.avatar})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
      ) : (
        <div className={clsx("symbol-label fs-3")}>
          <img
            alt="user"
            src={toAbsoluteUrl("/media/avatars/blank.png")}
            className="w-100"
          />
        </div>
      )}
    </div>
    <div className="d-flex flex-column">
      <Link
        to={`/user-management/users/active/detail/${
          user.user_id || user.id
        }/post`}
        className="text-dark fw-bold text-hover-primary mb-1 fs-6"
      >
        {user?.name || user?.user_name}
      </Link>
      <Link
        to={`/user-management/users/active/detail/${
          user.user_id || user.id
        }/post`}
        className="text-muted fw-semibold text-muted d-block fs-7 text-hover-primary"
      >
        {user?.email}
      </Link>
    </div>
  </div>
);
export default UserInfoCell;
