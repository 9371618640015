import axios, { AxiosResponse } from "axios";
import {
  ContentsQueryResponse,
  MembersQueryResponse,
  UsersQueryResponse,
  VideosQueryResponse,
} from "./_models";
import {
  ResponseAppDownloadsReport,
  ResponseLikeAndCommentReport,
  ResponseMultiUserActiveReport,
  ResponseRealTimeUserOnlineReport,
  ResponseVideoViewsReport,
} from "./QueryResponseProvider";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_ACTIVE_URL = `${API_URL}/dashboard/users/active`;
const GET_VIDEOS_REPORT_URL = `${API_URL}/dashboard/videos/report`;
const GET_CONTENTS_REPORT_URL = `${API_URL}/dashboard/articles/report`;
const GET_MEMBERS_REPORT_URL = `${API_URL}/dashboard/users/report`;
const GET_APP_DOWNLOADS_REPORT_URL = `${API_URL}/dashboard/app/download`;
const GET_VIDEO_VIEWS_REPORT_URL = `${API_URL}/dashboard/videos/view-report`;
const GET_MULTI_USER_ACTIVE_REPORT_URL = `${API_URL}/dashboard/app/active-user`;
const GET_REALTIME_USER_ACTIVE_REPORT_URL = `${API_URL}/dashboard/app/realtime-active-user`;
const GET_LIKE_AND_COMMENT_REPORT_URL = `${API_URL}/dashboard/article-like-comment-report`;
const getUserActive = (query: any): Promise<UsersQueryResponse> => {
  return (
    axios
      .get(`${GET_USERS_ACTIVE_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const listUserActive = d.data.data;
        return {
          data: listUserActive,
        };
      })
  );
};

const getVideosReport = (query: any): Promise<VideosQueryResponse> => {
  return (
    axios
      .get(`${GET_VIDEOS_REPORT_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const videosReport = d.data.data;
        return {
          data: videosReport,
        };
      })
  );
};

const getMembersReport = (query: any): Promise<MembersQueryResponse> => {
  return (
    axios
      .get(`${GET_MEMBERS_REPORT_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const membersReport = d.data.data;
        return {
          data: membersReport,
        };
      })
  );
};

const getContentsReport = (query: any): Promise<ContentsQueryResponse> => {
  return (
    axios
      .get(`${GET_CONTENTS_REPORT_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const contentsReport = d.data.data;
        return {
          data: contentsReport,
        };
      })
  );
};

const getAppDownloadsReport = (
  query: any
): Promise<ResponseAppDownloadsReport> => {
  const newQuery = { ...query };
  delete newQuery.type;
  return (
    axios
      .get(`${GET_APP_DOWNLOADS_REPORT_URL}`, {
        params: newQuery,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const appDownloadData = d.data.data;
        return {
          data: appDownloadData,
        };
      })
  );
};

const getVideoViewsReport = (query: any): Promise<ResponseVideoViewsReport> => {
  return (
    axios
      .get(`${GET_VIDEO_VIEWS_REPORT_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const videoViewsData = d.data.data;
        return {
          data: videoViewsData,
        };
      })
  );
};

const getMultiUserActiveViewsReport = (
  query: any
): Promise<ResponseMultiUserActiveReport> => {
  const newQuery = { ...query };
  delete newQuery.type;
  return (
    axios
      .get(`${GET_MULTI_USER_ACTIVE_REPORT_URL}`, {
        params: newQuery,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const multiUserActiveData = d.data.data;
        return {
          data: multiUserActiveData,
        };
      })
  );
};

const getRealTimeUserOnlineReport = (
  query: any
): Promise<ResponseRealTimeUserOnlineReport> => {
  return (
    axios
      .get(`${GET_REALTIME_USER_ACTIVE_REPORT_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const realTimeUserActiveData = d.data.data;
        return {
          data: realTimeUserActiveData,
        };
      })
  );
};

const getLikeAndCommentReport = (
  query: any
): Promise<ResponseLikeAndCommentReport> => {
  return (
    axios
      .get(`${GET_LIKE_AND_COMMENT_REPORT_URL}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const likeAndCommentData = d.data.data;
        return {
          data: likeAndCommentData,
        };
      })
  );
};

export {
  getUserActive,
  getVideosReport,
  getContentsReport,
  getMembersReport,
  getAppDownloadsReport,
  getVideoViewsReport,
  getMultiUserActiveViewsReport,
  getRealTimeUserOnlineReport,
  getLikeAndCommentReport,
};
