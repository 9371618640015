import { FC, useState, createContext, useContext } from "react";
import {
  QueryState,
  initialQueryRequest,
  WithChildren,
} from "_metronic/helpers";

const QueryRequestContext = createContext<any>(initialQueryRequest);

const QueryRequestProvider: FC<WithChildren> = ({ children }) => {
  const [state, setState] = useState<QueryState>(initialQueryRequest.state);
  const [pageInstance, setPageInstance] = useState(Math.random());

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = {
      ...state,
      ...updates,
    } as QueryState;
    setState(updatedState);
  };

  const resetState = (updates: Partial<QueryState>) => {
    setState((updates as QueryState) || initialQueryRequest.state);
    // setPageInstance(Math.random);
  };

  return (
    <QueryRequestContext.Provider
      value={{ state, updateState, resetState, pageInstance, setPageInstance }}
    >
      {children}
    </QueryRequestContext.Provider>
  );
};

const useQueryRequest = () => useContext(QueryRequestContext);
export { QueryRequestProvider, useQueryRequest };
